import { useCallback, useMemo } from "react";
import { IUnit, IUnitCompleted } from "../../store/api/umbraco-api";
import ContentUnit from "./content-unit/content-unit";
import FormUnit from "./form-unit";
import {
  isAIConversation,
  isContentUnit,
  isFormUnit,
  isMultiChoiceExercise,
  isOpenResponseExercise,
} from "./unit.types";
import ExerciseQuestion from "./exercise-question/exercise-question";
import OpenExerciseQuestion from "./open-exercise-question";
import AIConversationUnit from "./ai-conversation-unit";
import ExerciseQuestionCompleted from "./exercise-question/exercise-question-completed";
import { Box, Card, Link, Typography } from "@mui/joy";
import RichText from "../rich-text";
import { Note, SendOutlined, UploadFile, UploadFileRounded } from "@mui/icons-material";

interface UnitProps {
  unit: IUnit;
  onUnitCompleteChange(t: any): void;
  setUnitFinished(finish: boolean): void;
  skip?: () => void;
}

const Unit = ({
  unit,
  onUnitCompleteChange: onChange,
  setUnitFinished,
  skip,
}: UnitProps) => {
  const startTime = useMemo(() => new Date().toISOString(), []);
  const setUnitComplete = useCallback(
    (extraArgs: any) => {
      onChange({
        start: startTime,
        end: new Date().toISOString(),
        id: unit.id,
        type: unit.type,
        ...extraArgs,
      });
    },
    [onChange, unit]
  );

  if (isOpenResponseExercise(unit)) {
    return (
      <OpenExerciseQuestion
        {...unit}
        key={unit.id}
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        skip={skip}
      />
    );
  }

  if (isMultiChoiceExercise(unit)) {
    return (
      <ExerciseQuestion
        {...unit}
        key={unit.id}
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        onChoiceChanged={() => null} //todo send res
        skip={skip}
      />
    );
  }

  if (isFormUnit(unit)) {
    return (
      <FormUnit
        setUnitFinished={setUnitFinished}
        setUnitComplete={setUnitComplete}
        unit={unit}
      />
    );
  }

  if (isContentUnit(unit)) {
    return (
      <ContentUnit
        setUnitComplete={setUnitComplete}
        setUnitFinished={setUnitFinished}
        unit={unit}
      />
    );
  }

  if (isAIConversation(unit)) {
    return (
      <AIConversationUnit
        setUnitComplete={setUnitComplete}
        setUnitFinished={setUnitFinished}
        unit={unit}
      />
    );
  }

  return null;
};

export default Unit;

interface UnitCompletedProps {
  unit: IUnit;
  completed: IUnitCompleted
}

interface IExerciseQuestionCompleted extends IUnitCompleted {
  answerIndex: number;
}


export const UnitCompleted = ({
  unit,
  completed
}: UnitCompletedProps) => {
  if (isOpenResponseExercise(unit)) {
    const { title, question, solution, answerType } = unit as IUnit & { title?: string; question: string; solution?: string; answerType: "text" | "image"};
    const { answer } = completed as IUnitCompleted & { answer?: string }
    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
      }}
    >
      {title && <Typography level="title-lg">{title}</Typography>}
      <RichText content={question} /> 
      {answer && <Card>
          <Typography level="title-md">התשובה שלך:</Typography>
          {answerType === "image" 
            ? <>{answer.split(",").map(url => <Link href={url} target="blank">צפה בתמונה</Link>)}</>
            : <Typography>{answer}</Typography>
          }
        </Card>}
      {solution && 
        <Card>
          <Typography level="title-md">הפתרון המלא:</Typography>
          <RichText content={solution} />
        </Card>
      }
    </Box>
    );
  }

  if (isMultiChoiceExercise(unit)) {
    const answerIndex = (completed as IExerciseQuestionCompleted).answerIndex;
    return (
      <ExerciseQuestionCompleted
        isAnswered={true}
        isCorrectAnswer={unit.correctAnswer === answerIndex}
        choice={answerIndex}
        setChoice={() => null}
        {...unit}
      />
    );
  }

  if (isFormUnit(unit)) {
    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
      }}
    ><Typography level="title-lg" display={"flex"} alignItems={"center"}>
      טופס נשלח
      <UploadFile/>
      </Typography>
      </Box>
    );
  }

  if (isContentUnit(unit)) {
    return (
      <ContentUnit
        setUnitComplete={() => null}
        setUnitFinished={() => null}
        unit={unit}
      />
    );
  }

  if (isAIConversation(unit)) {
    return (
      <AIConversationUnit
        setUnitComplete={() => null}
        setUnitFinished={() => null}
        unit={unit}
      />
    );
  }

  return null;
};
