import { extendTheme } from "@mui/joy/styles";

export const themeBase = {
  components: {},
  colorSchemes: {
    new_design: {
      palette: {
        background: "#F5F6FA",
        white: "#FFFFFF",
        purple: "#8280FF",
        purple_light: "#E6E6FF",
        purple_dark: "#27259F",
        blue: "#69A6F7",
        blue_light: "#D8E9FF",
        green: "#D7F1B3",
        green_finish: "#2BC34C",
        gray: "#636466",
        gray_light: "#F5F6FA",
        gray_extra_light: "#fafafa",
        gray_dark: "#323232",
        black: "#000000",
        orange: "#FF9871",
        orange_light: "#FFDED1",
        yellow: "#FEC53D",
        yellow_light: "#FFF3D6",
      },
    },
    light: {
      palette: {
        primary: {
          "50": "#f0fdf4",
          "100": "#dcfce7",
          "200": "#bbf7d0",
          "300": "#86efac",
          "400": "#4ade80",
          "500": "#22c55e",
          "600": "#16a34a",
          "700": "#15803d",
          "800": "#166534",
          "900": "#14532d",
          white: "#FFFFFF",
          black: "#000000",
          gray: "#BDBDBD",
          green: "#22C55D",
          green_bright: "#53EC8A",
          green_very_bright: "#CDFFDF",
          blue_bright: "#81E6E5",
          gray_light: "#ECEBF0",
        },
      },
    },
    dark: {},
  },
};

export const theme = extendTheme(themeBase);
