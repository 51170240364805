import { Box } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useReward } from "react-rewards";
import { Button } from "../..";
import ExerciseQuestionCompleted from "./exercise-question-completed";

interface Question {
  id: number;
  title: string;
  answers: string[];
  correctAnswer?: number;
  solution?: string | null;
  question: string;
}

interface ExerciseQuestionProps extends Question {
  onAnswerClick?: boolean;
  disabled?: boolean;
  onChoiceChanged(choiceIndex: number): void;
  setUnitFinished(finish: boolean): void;
  setUnitComplete(args: any): void;
  skip?: () => void;
}

enum QuestionState {
  ANSWERING,
  WRONG_ANSWER,
  CORRECT_ANSWER,
}

const ExerciseQuestion = ({
  answers,
  onChoiceChanged,
  title,
  solution,
  question,
  correctAnswer,
  setUnitFinished,
  setUnitComplete,
  skip,
}: ExerciseQuestionProps) => {
  const [chosen, setChosen] = useState<null | number>(null);
  
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.ANSWERING
  );

  const isAnswered = useMemo(
    () => questionState !== QuestionState.ANSWERING,
    [questionState]
  );

  const onAnswer = () => {
    if (chosen !== null) {
      if (chosen === correctAnswer) {
        setQuestionState(QuestionState.CORRECT_ANSWER);
        reward();
      } else {
        setQuestionState(QuestionState.WRONG_ANSWER);
      }
      setUnitComplete({ answerIndex: chosen });
    }
  };

  useEffect(() => {
    if (chosen !== null) {
      onChoiceChanged(chosen);
    }
  }, [chosen, onChoiceChanged]);

  useEffect(() => {
    setUnitFinished(isAnswered);
  }, [isAnswered, setUnitFinished]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <ExerciseQuestionCompleted
        title={title}
        question={question}
        answers={answers}
        isAnswered={isAnswered}
        isCorrectAnswer={questionState === QuestionState.CORRECT_ANSWER}
        choice={chosen !== null ? chosen : -1}
        setChoice={setChosen}
        solution={solution}
      />
      {!isAnswered && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          }}
        >
          <Button
            sx={{
              width: 150,
              justifySelf: "flex-start",
              alignSelf: "flex-start",
            }}
            disabled={chosen === null}
            onClick={onAnswer}
            variant="outlined"
          >
            שלח תשובה
          </Button>
          {
          skip
          ? <Button
              sx={{ opacity: 0.5 }}
              onClick={skip}
              variant="outlined"
              color="neutral"
            >
              דלג
            </Button>
          : null
          }
        </Box>
      )}

      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default ExerciseQuestion;
