import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthLogin: build.query<GetAuthLoginApiResponse, GetAuthLoginApiArg>({
      query: () => ({ url: `/auth/login` }),
    }),
    postAuthLogin: build.mutation<
      PostAuthLoginApiResponse,
      PostAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.loginRequest,
      }),
    }),
    postAuthLogout: build.mutation<
      PostAuthLogoutApiResponse,
      PostAuthLogoutApiArg
    >({
      query: () => ({ url: `/auth/logout`, method: "POST" }),
    }),
    postAuthSignup: build.mutation<
      PostAuthSignupApiResponse,
      PostAuthSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/signup`,
        method: "POST",
        body: queryArg.signupRequest,
      }),
    }),
    postChat: build.mutation<PostChatApiResponse, PostChatApiArg>({
      query: (queryArg) => ({
        url: `/chat`,
        method: "POST",
        body: queryArg.createConversationRequest,
      }),
    }),
    postChatByConversationIdMessages: build.mutation<
      PostChatByConversationIdMessagesApiResponse,
      PostChatByConversationIdMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/${queryArg.conversationId}/messages`,
        method: "POST",
        body: queryArg.createMessageRequest,
      }),
    }),
    getChatByConversationIdMessages: build.query<
      GetChatByConversationIdMessagesApiResponse,
      GetChatByConversationIdMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/${queryArg.conversationId}/messages`,
      }),
    }),
    getChatById: build.query<GetChatByIdApiResponse, GetChatByIdApiArg>({
      query: (queryArg) => ({ url: `/chat/${queryArg.id}` }),
    }),
    getOrganizations: build.query<
      GetOrganizationsApiResponse,
      GetOrganizationsApiArg
    >({
      query: () => ({ url: `/organizations` }),
    }),
    getCoursesByCourseIdChapters: build.query<
      GetCoursesByCourseIdChaptersApiResponse,
      GetCoursesByCourseIdChaptersApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseId}/chapters` }),
    }),
    getCoursesById: build.query<
      GetCoursesByIdApiResponse,
      GetCoursesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.id}` }),
    }),
    getExercisesByExerciseIdUnits: build.query<
      GetExercisesByExerciseIdUnitsApiResponse,
      GetExercisesByExerciseIdUnitsApiArg
    >({
      query: (queryArg) => ({ url: `/exercises/${queryArg.exerciseId}/units` }),
    }),
    postExercisesByExerciseIdUnitsAndId: build.mutation<
      PostExercisesByExerciseIdUnitsAndIdApiResponse,
      PostExercisesByExerciseIdUnitsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/exercises/${queryArg.exerciseId}/units/${queryArg.id}`,
        method: "POST",
        body: queryArg.iUnitCompleted,
      }),
    }),
    getExercisesByExerciseIdUnitsAndUnitId: build.query<
      GetExercisesByExerciseIdUnitsAndUnitIdApiResponse,
      GetExercisesByExerciseIdUnitsAndUnitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/exercises/${queryArg.exerciseId}/units/${queryArg.unitId}`,
      }),
    }),
    getExercisesByExerciseIdUnitsCurrent: build.query<
      GetExercisesByExerciseIdUnitsCurrentApiResponse,
      GetExercisesByExerciseIdUnitsCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/exercises/${queryArg.exerciseId}/units/current`,
      }),
    }),
    getExercisesById: build.query<
      GetExercisesByIdApiResponse,
      GetExercisesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/exercises/${queryArg.id}` }),
    }),
    getFeaturesByFeatureId: build.query<
      GetFeaturesByFeatureIdApiResponse,
      GetFeaturesByFeatureIdApiArg
    >({
      query: (queryArg) => ({ url: `/features/${queryArg.featureId}` }),
    }),
    postFeaturesByFeatureId: build.mutation<
      PostFeaturesByFeatureIdApiResponse,
      PostFeaturesByFeatureIdApiArg
    >({
      query: (queryArg) => ({
        url: `/features/${queryArg.featureId}`,
        method: "POST",
        body: queryArg.iUnitCompleted,
      }),
    }),
    getFeaturesByFeatureIdUnits: build.query<
      GetFeaturesByFeatureIdUnitsApiResponse,
      GetFeaturesByFeatureIdUnitsApiArg
    >({
      query: (queryArg) => ({ url: `/features/${queryArg.featureId}/units` }),
    }),
    postFeaturesByFeatureIdUnitsAndUnitIdSkip: build.mutation<
      PostFeaturesByFeatureIdUnitsAndUnitIdSkipApiResponse,
      PostFeaturesByFeatureIdUnitsAndUnitIdSkipApiArg
    >({
      query: (queryArg) => ({
        url: `/features/${queryArg.featureId}/units/${queryArg.unitId}/skip`,
        method: "POST",
      }),
    }),
    getLoginProviders: build.query<
      GetLoginProvidersApiResponse,
      GetLoginProvidersApiArg
    >({
      query: () => ({ url: `/loginProviders` }),
    }),
    getLoginProvidersByProviderUrlName: build.query<
      GetLoginProvidersByProviderUrlNameApiResponse,
      GetLoginProvidersByProviderUrlNameApiArg
    >({
      query: (queryArg) => ({
        url: `/loginProviders/${queryArg.providerUrlName}`,
      }),
    }),
    getLessonsById: build.query<
      GetLessonsByIdApiResponse,
      GetLessonsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lessons/${queryArg.id}` }),
    }),
    getLessonsByLessonIdUnits: build.query<
      GetLessonsByLessonIdUnitsApiResponse,
      GetLessonsByLessonIdUnitsApiArg
    >({
      query: (queryArg) => ({ url: `/lessons/${queryArg.lessonId}/units` }),
    }),
    postLessonsByLessonIdUnitsAndId: build.mutation<
      PostLessonsByLessonIdUnitsAndIdApiResponse,
      PostLessonsByLessonIdUnitsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lessons/${queryArg.lessonId}/units/${queryArg.id}`,
        method: "POST",
        body: queryArg.iUnitCompleted,
      }),
    }),
    getLessonsByLessonIdUnitsAndUnitId: build.query<
      GetLessonsByLessonIdUnitsAndUnitIdApiResponse,
      GetLessonsByLessonIdUnitsAndUnitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lessons/${queryArg.lessonId}/units/${queryArg.unitId}`,
      }),
    }),
    getStatsCoursesByCourseId: build.query<
      GetStatsCoursesByCourseIdApiResponse,
      GetStatsCoursesByCourseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/stats/courses/${queryArg.courseId}`,
        params: {
          topicId: queryArg.topicId,
          startTime: queryArg.startTime,
          endTime: queryArg.endTime,
        },
      }),
    }),
    getStatsCoursesByCourseIdTopics: build.query<
      GetStatsCoursesByCourseIdTopicsApiResponse,
      GetStatsCoursesByCourseIdTopicsApiArg
    >({
      query: (queryArg) => ({
        url: `/stats/courses/${queryArg.courseId}/topics`,
      }),
    }),
    getStatsExportCourseByCourseId: build.query<
      GetStatsExportCourseByCourseIdApiResponse,
      GetStatsExportCourseByCourseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/stats/export/course/${queryArg.courseId}`,
      }),
    }),
    postUploadByUnitId: build.mutation<
      PostUploadByUnitIdApiResponse,
      PostUploadByUnitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/upload/${queryArg.unitId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as umbracoApi };
export type GetAuthLoginApiResponse = /** status 200 Success */ LoginResponse;
export type GetAuthLoginApiArg = void;
export type PostAuthLoginApiResponse = /** status 200 Success */ LoginResponse;
export type PostAuthLoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostAuthLogoutApiResponse = unknown;
export type PostAuthLogoutApiArg = void;
export type PostAuthSignupApiResponse = /** status 200 Success */ LoginResponse;
export type PostAuthSignupApiArg = {
  signupRequest: SignupRequest;
};
export type PostChatApiResponse =
  /** status 200 Success */ CreateConversationResponse;
export type PostChatApiArg = {
  createConversationRequest: CreateConversationRequest;
};
export type PostChatByConversationIdMessagesApiResponse =
  /** status 200 Success */ CreateMessageRequest;
export type PostChatByConversationIdMessagesApiArg = {
  conversationId: string;
  createMessageRequest: CreateMessageRequest;
};
export type GetChatByConversationIdMessagesApiResponse =
  /** status 200 Success */ MessageDto[];
export type GetChatByConversationIdMessagesApiArg = {
  conversationId: string;
};
export type GetChatByIdApiResponse =
  /** status 200 Success */ GetConversationResponse;
export type GetChatByIdApiArg = {
  id: string;
};
export type GetOrganizationsApiResponse =
  /** status 200 Success */ Organization[];
export type GetOrganizationsApiArg = void;
export type GetCoursesByCourseIdChaptersApiResponse =
  /** status 200 Success */ Chapter[];
export type GetCoursesByCourseIdChaptersApiArg = {
  courseId: number;
};
export type GetCoursesByIdApiResponse =
  /** status 200 Success */ CourseResponse;
export type GetCoursesByIdApiArg = {
  id: number;
};
export type GetExercisesByExerciseIdUnitsApiResponse =
  /** status 200 Success */ ExerciseUnit[];
export type GetExercisesByExerciseIdUnitsApiArg = {
  exerciseId: number;
};
export type PostExercisesByExerciseIdUnitsAndIdApiResponse =
  /** status 200 Success */ ExerciseUnitCompletionResponse;
export type PostExercisesByExerciseIdUnitsAndIdApiArg = {
  exerciseId: number;
  id: number;
  iUnitCompleted: IUnitCompleted;
};
export type GetExercisesByExerciseIdUnitsAndUnitIdApiResponse =
  /** status 200 Success */ ExerciseUnitContentResponse;
export type GetExercisesByExerciseIdUnitsAndUnitIdApiArg = {
  exerciseId: number;
  unitId: number;
};
export type GetExercisesByExerciseIdUnitsCurrentApiResponse =
  /** status 200 Success */ IUnit | /** status 204 No Content */ void;
export type GetExercisesByExerciseIdUnitsCurrentApiArg = {
  exerciseId: number;
};
export type GetExercisesByIdApiResponse =
  /** status 200 Success */ ExerciseResponse;
export type GetExercisesByIdApiArg = {
  id: number;
};
export type GetFeaturesByFeatureIdApiResponse =
  /** status 200 Success */ FeatureresponseWithUnit;
export type GetFeaturesByFeatureIdApiArg = {
  featureId: number;
};
export type PostFeaturesByFeatureIdApiResponse = unknown;
export type PostFeaturesByFeatureIdApiArg = {
  featureId: number;
  iUnitCompleted: IUnitCompleted;
};
export type GetFeaturesByFeatureIdUnitsApiResponse =
  /** status 200 Success */ Unit[];
export type GetFeaturesByFeatureIdUnitsApiArg = {
  featureId: number;
};
export type PostFeaturesByFeatureIdUnitsAndUnitIdSkipApiResponse = unknown;
export type PostFeaturesByFeatureIdUnitsAndUnitIdSkipApiArg = {
  featureId: number;
  unitId: number;
};
export type GetLoginProvidersApiResponse =
  /** status 200 Success */ LoginProviderResponse;
export type GetLoginProvidersApiArg = void;
export type GetLoginProvidersByProviderUrlNameApiResponse =
  /** status 200 Success */ LoginProviderResponse;
export type GetLoginProvidersByProviderUrlNameApiArg = {
  providerUrlName: string;
};
export type GetLessonsByIdApiResponse =
  /** status 200 Success */ LessonResponse;
export type GetLessonsByIdApiArg = {
  id: number;
};
export type GetLessonsByLessonIdUnitsApiResponse =
  /** status 200 Success */ LessonUnit[];
export type GetLessonsByLessonIdUnitsApiArg = {
  lessonId: number;
};
export type PostLessonsByLessonIdUnitsAndIdApiResponse =
  /** status 200 Success */ LessonUnitCompletionResponse;
export type PostLessonsByLessonIdUnitsAndIdApiArg = {
  lessonId: number;
  id: number;
  iUnitCompleted: IUnitCompleted;
};
export type GetLessonsByLessonIdUnitsAndUnitIdApiResponse =
  /** status 200 Success */ LessonUnitContentResponse;
export type GetLessonsByLessonIdUnitsAndUnitIdApiArg = {
  lessonId: number;
  unitId: number;
};
export type GetStatsCoursesByCourseIdApiResponse =
  /** status 200 Success */ TopicStats;
export type GetStatsCoursesByCourseIdApiArg = {
  courseId: number;
  topicId?: number;
  startTime?: string;
  endTime?: string;
};
export type GetStatsCoursesByCourseIdTopicsApiResponse =
  /** status 200 Success */ TopicTree[];
export type GetStatsCoursesByCourseIdTopicsApiArg = {
  courseId: number;
};
export type GetStatsExportCourseByCourseIdApiResponse = unknown;
export type GetStatsExportCourseByCourseIdApiArg = {
  courseId: number;
};
export type PostUploadByUnitIdApiResponse = unknown;
export type PostUploadByUnitIdApiArg = {
  unitId: number;
  body: {
    files?: Blob[];
  };
};
export type Gender = "Other" | "Male" | "Female";
export type LoginResponse = {
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  isTeacher?: boolean;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type LoginRequest = {
  username?: string;
  password?: string;
  keepMeLoggedIn?: boolean;
};
export type SignupRequest = {
  signupProvider?: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  yearOfBirth?: number | null;
  phoneNumber?: string | null;
  gender?: Gender;
};
export type CreateConversationResponse = {
  conversationId?: string;
  message?: string;
};
export type MessageSender = "AI" | "Student";
export type MessageDto = {
  id?: string;
  time?: string;
  sender?: MessageSender;
  content?: string;
};
export type ConversationExistResponse = {
  conversationId?: string;
  existingMessages?: MessageDto[];
};
export type CreateConversationRequest = {
  initialMessage?: string;
  resourceId?: number | null;
};
export type CreateMessageRequest = {
  message?: string;
};
export type GetConversationResponse = {
  id?: string;
  isOpen?: boolean;
};
export type Course = {
  id?: number;
  name?: string;
};
export type Organization = {
  name?: string;
  logo?: string;
  courses?: Course[];
};
export type FeatureType = "SmartExercise" | "Lesson" | "SmartLesson";
export type ProgressStatus = "NotStarted" | "InProgress" | "Finished";
export type Feature = {
  id?: number;
  type?: FeatureType;
  name?: string;
  description?: string;
  isLocked?: boolean;
  status?: ProgressStatus;
  totalUnitNumber?: number;
  completedUnitNumber?: number;
  learningMinutes?: number;
  group?: string;
};
export type Chapter = {
  id?: number;
  title?: string;
  total?: number;
  completed?: number;
  learningMinutes?: number;
  features?: Feature[];
};
export type CourseResponse = {
  id?: number;
  name?: string;
  logo?: string;
  shortDescription?: string;
  dailyStreak?: number;
  showStats?: boolean;
};
export type UnitTypes =
  | "ContentUnit"
  | "OpenResponseExercise"
  | "MultiChoiceExercise"
  | "Form"
  | "AIConversation";
export type ExerciseUnit = {
  id?: number;
  name?: string;
  type?: UnitTypes;
  isSuccess?: boolean;
};
export type ExerciseUnitCompletionResponse = {
  isSuccess?: boolean;
  score?: number;
};
export type IUnitCompleted = {
  id?: number;
  type?: UnitTypes;
  start?: string;
  end?: string;
};
export type IUnit = {
  id?: number;
  type?: UnitTypes;
};
export type ExerciseUnitContentResponse = {
  unit?: IUnit;
  isCompleted?: boolean;
  completion?: IUnitCompleted;
};
export type ExerciseStatus = "NotStarted" | "InProgress" | "Completed";
export type ExerciseResponse = {
  id?: number;
  name?: string;
  description?: string;
  status?: ExerciseStatus;
  totalUnits?: number;
  completedUnits?: number;
};
export type FeatureResponse = {
  id?: number;
  name?: string;
  type?: FeatureType;
  unitsTotal?: number;
  unitsCompleted?: number;
  status?: ProgressStatus;
};
export type FeatureresponseWithUnit = {
  unit?: IUnit;
  feature?: FeatureResponse;
};
export type Unit = {
  id?: number;
  name?: string;
  type?: UnitTypes;
};
export type LoginProviderResponse = {
  courseId?: number;
  courseName?: string;
  logoUrl?: string[];
  isSignup?: boolean;
};
export type LessonStatus = "NotStarted" | "InProgress" | "Completed";
export type LessonResponse = {
  id?: number;
  name?: string;
  description?: string;
  status?: LessonStatus;
  totalUnits?: number;
  completedUnits?: number;
};
export type LessonUnit = {
  id?: number;
  name?: string;
  type?: UnitTypes;
  isComplete?: boolean;
  isSuccess?: boolean;
};
export type LessonUnitCompletionResponse = {
  isSuccess?: boolean;
  score?: number;
};
export type LessonUnitContentResponse = {
  unit?: IUnit;
  isCompleted?: boolean;
  completion?: IUnitCompleted;
};
export type TopicStats = {
  totalAnswerCount?: number;
  correctAnswerCount?: number;
  learningMinutes?: number;
};
export type TopicTree = {
  id?: number;
  name?: string;
  children?: TopicTree[];
};
export const {
  useGetAuthLoginQuery,
  usePostAuthLoginMutation,
  usePostAuthLogoutMutation,
  usePostAuthSignupMutation,
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
  useGetChatByConversationIdMessagesQuery,
  useGetChatByIdQuery,
  useGetOrganizationsQuery,
  useGetCoursesByCourseIdChaptersQuery,
  useGetCoursesByIdQuery,
  useGetExercisesByExerciseIdUnitsQuery,
  usePostExercisesByExerciseIdUnitsAndIdMutation,
  useGetExercisesByExerciseIdUnitsAndUnitIdQuery,
  useGetExercisesByExerciseIdUnitsCurrentQuery,
  useGetExercisesByIdQuery,
  useGetFeaturesByFeatureIdQuery,
  usePostFeaturesByFeatureIdMutation,
  useGetFeaturesByFeatureIdUnitsQuery,
  usePostFeaturesByFeatureIdUnitsAndUnitIdSkipMutation,
  useGetLoginProvidersQuery,
  useGetLoginProvidersByProviderUrlNameQuery,
  useGetLessonsByIdQuery,
  useGetLessonsByLessonIdUnitsQuery,
  usePostLessonsByLessonIdUnitsAndIdMutation,
  useGetLessonsByLessonIdUnitsAndUnitIdQuery,
  useGetStatsCoursesByCourseIdQuery,
  useGetStatsCoursesByCourseIdTopicsQuery,
  useGetStatsExportCourseByCourseIdQuery,
  usePostUploadByUnitIdMutation,
} = injectedRtkApi;
