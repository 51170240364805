import { ContentUnit as ContentUnitType } from "../unit.types";
import { useEffect } from "react";
import ContentUnitCompleted from "./content-unit-completed";

interface ContentUnitProps {
  unit: ContentUnitType;
  setUnitFinished(finish: boolean): void;
  setUnitComplete(args: any): void;
}

const ContentUnit = ({
  unit: { title, content },
  setUnitFinished,
  setUnitComplete,
}: ContentUnitProps) => {
  useEffect(() => {
    setUnitFinished(true);
    setUnitComplete({});
  }, [setUnitFinished, setUnitComplete]);

  return <ContentUnitCompleted title={title} content={content} />;
};



export default ContentUnit;
