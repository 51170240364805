import { Textarea } from "@mui/joy";
import { useMemo, useState } from "react";
import { IUnitCompleted } from "../../../store/api/umbraco-api";
import { UnitProps } from "../interfaces";
import FinishButton from "../shared/btn";
import Loading from "../shared/loading";
import { TextAnswer } from "./answer";
import useUnit from "../../../context/useUnit";

const TextInputs = ({ unitId, unitType, solution }: UnitProps & { solution?: string | null }) => {
    const { unitContent, unitCompletion } = useUnit();
    const startTime = useMemo(() => new Date(), []);
    const [text, setText] = useState("");

    const handleSend = () => {
        unitCompletion.post({
            id: unitId,
            type: unitType,
            start: startTime.toISOString(),
            end: new Date().toISOString(),
            answer: text
        } as IUnitCompleted)
    }

    if (unitCompletion.isLoading) {
        return <Loading />;
    }

    if (unitCompletion.response) {
        return <TextAnswer text={text} solution={solution} isSuccess={unitCompletion.response.isSuccess} score={unitCompletion.response.score}/>;
    }

    return (
        <>
            <Textarea
                minRows={4}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <FinishButton onClick={handleSend} title={"שלח תשובה"} disabled={text.length === 0 || unitContent?.data?.isCompleted}/>
        </>);
};

export default TextInputs;