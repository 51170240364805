import { Box, RadioGroup, Typography, FormLabel, Radio } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useReward } from "react-rewards";
import { Button } from "..";
import {
  IUnit,
  IUnitCompleted
} from "../../store/api/umbraco-api";
import { UnitProps } from "./interfaces";
import Error from "./shared/error";
import Loading from "./shared/loading";
import useElapsedTime from "../../hooks/useElapsedTime";
import RichText from "../rich-text";
import { UnitContentResponse } from "../../context/unit-context";
import useUnit from "../../context/useUnit";

enum QuestionState {
  ANSWERING,
  WRONG_ANSWER,
  CORRECT_ANSWER,
}

interface MultiChoiceContentResponse extends UnitContentResponse {
  unit: IUnit & {
    id: number;
    title: string;
    answers: string[];
    correctAnswer?: number;
    solution?: string | null;
    question: string;
  };
  completion: IUnitCompleted & { answerIndex: number };
}

const MultiChoiceExercise = ({ unitId }: UnitProps) => {

  const { unitContent, unitCompletion } = useUnit<MultiChoiceContentResponse>();
  const fetchTime = useElapsedTime();

  const [chosen, setChosen] = useState<number | null>(null);
  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.ANSWERING
  );

  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  const isAnswered = useMemo(
    () => questionState !== QuestionState.ANSWERING,
    [questionState]
  );

  useEffect(() => {
    if (unitContent.data?.unit.id === unitId && !unitContent.isLoading) {
      const { unit, isCompleted, completion } = unitContent.data as MultiChoiceContentResponse;
      if (isCompleted) {
        setChosen(completion.answerIndex);
        setQuestionState(completion.answerIndex === unit.correctAnswer ? QuestionState.CORRECT_ANSWER : QuestionState.WRONG_ANSWER);
      }
    }
  }, [unitContent.data, unitContent.isLoading])

  if ( !unitContent.data || unitContent.isLoading) {
    return <Loading />;
  }

  if (unitContent.isError || !unitContent.data) {
    return <Error />;
  }

  const { unit } = unitContent.data;

  const onAnswer = async () => {
    if (chosen === null) return;

    const correct = chosen === unit?.correctAnswer;
    setQuestionState(
      correct ? QuestionState.CORRECT_ANSWER : QuestionState.WRONG_ANSWER
    );

    if (correct) {
      reward();
    }

    // Post completion data
    try {
      unitCompletion.post({
        id: unitId,
        type: unit.type, 
        start: fetchTime.toISOString(),
        end: new Date().toISOString(),
        answerIndex: chosen
      } as IUnitCompleted)
    } catch (err) {
      console.error("Error posting unit data:", err);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Title */}
      {unit.title && (
        <Typography level="h4" sx={{ fontWeight: "bold" }}>
          {unit.title}
        </Typography>
      )}

      {/* Question Text */}
      <RichText content={unit.question} />

      {/* Answers */}
      <RadioGroup name="answers" sx={{ gap: 1 }}>
        {unit.answers && unit.answers.map((ans, index) => (
          <span key={index}>
            <FormLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Radio
                  value={index}
                  checked={chosen === index}
                  onChange={() => setChosen(index)}
                  disabled={isAnswered}
                />
                <RichText content={ans} />
              </Box>
            </FormLabel>
          </span>
        ))}
      </RadioGroup>

      {/* Feedback Section */}
      {isAnswered && (
        <Box
          sx={{
            p: 2,
            border: "1px solid",
            borderColor:
              questionState === QuestionState.CORRECT_ANSWER
                ? "success.main"
                : "danger.main",
            borderRadius: "8px",
            backgroundColor:
              questionState === QuestionState.CORRECT_ANSWER
                ? "success.light"
                : "danger.light",
          }}
        >
          <Typography
            color={
              questionState === QuestionState.CORRECT_ANSWER
                ? "success"
                : "danger"
            }
          >
            {questionState === QuestionState.CORRECT_ANSWER
              ? "תשובה נכונה"
              : "הפעם טעית"}
          </Typography>
          {unit.solution && (
            <RichText content={unit.solution} />
          )}
        </Box>
      )}

      {/* Action Buttons */}
      {!isAnswered && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            sx={{ width: 150 }}
            disabled={chosen === null}
            onClick={onAnswer}
            variant="outlined"
          >
            שלח תשובה
          </Button>
        </Box>
      )}

      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default MultiChoiceExercise;
