import { Typography, Box, Button } from "@mui/joy";
import { useMemo, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { FaRegImages, FaCamera } from "react-icons/fa";
import { IUnitCompleted } from "../../../store/api/umbraco-api";
import { themeBase } from "../../../theme";
import { UnitProps } from "../interfaces";
import FinishButton from "../shared/btn";
import Loading from "../shared/loading";
import { uploadImages } from "./uploadImages";
import Answer from "./answer";
import useUnit from "../../../context/useUnit";

const ImageInputs = ({ unitId, unitType, solution }: UnitProps & { solution?: string | null }) => {

    const { unitCompletion } = useUnit();
    const startTime = useMemo(() => new Date(), []);
    const [images, setImages] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const cameraInputRef = useRef<HTMLInputElement | null>(null);
    const [uploadUrls, setUploadUrls] = useState<string[]>([]);
  
    
    const handleButtonClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
  
    const handleCameraClick = () => {
      if (cameraInputRef.current) {
        cameraInputRef.current.click();
      }
    };
  
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files);
        setImages((prevImages) => [...prevImages, ...selectedFiles]);
      }
    };
  
    const handleSend = async () => {
      if (images.length === 0) return;
  
      const urls = await uploadImages(unitId, images);
      if (!urls?.length) return;

      setUploadUrls(urls);

      unitCompletion.post({
        id: unitId,
        type: unitType,
        start: startTime.toISOString(),
        end: new Date().toISOString(),
        answer: urls.join(',')
      } as IUnitCompleted)
    }
  
    if (unitCompletion.isLoading) {
      return <Loading />;
    }
  
    if (unitCompletion.response) {
      return <Answer solution={solution} {...unitCompletion.response}>
        <Box>
          {uploadUrls.map(url => <img src={url} />)}
        </Box>
      </Answer>
    }
  
    return (
      <>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          ref={fileInputRef}
          style={{ display: "none" }} 
        />
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleImageUpload}
          ref={cameraInputRef}
          style={{ display: "none" }} 
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            sx={{ gap: 2 }}
            onClick={handleButtonClick}
            variant="outlined"
          >
            <FaRegImages />
            העלה תמונות
          </Button>
          {isMobile && (
            <Button
              sx={{ gap: 2 }}
              onClick={handleCameraClick}
              variant="outlined"
            >
              <FaCamera />
              צלם תמונה
            </Button>
          )}
        </Box>
        {images.length > 0 && (
          <Box sx={{ gap: 1, display: "grid" }}>
            {images.map((image, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor:
                    themeBase.colorSchemes.light.palette.primary
                      .gray_light,
                  padding: 1,
                  borderRadius: "5px",
                }}
              >
                <Typography fontSize={12}>{image.name}</Typography>
              </Box>
          ))}
          <Button
            sx={{
              marginTop: 2,
              alignSelf: "flex-start",
            }}
            onClick={() => setImages([])}
            variant="outlined"
          >
            הסר תמונות
          </Button>
        </Box>
        )}
        <Box>
          <FinishButton onClick={handleSend} disabled={images.length === 0} title="שלח תשובה" />
        </Box>
      </>
    );
  };

  export default ImageInputs;