import React from "react";
import { Box, Typography, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Feature,
  IUnit,
  Unit as SidebarUnit,
} from "../../../store/api/umbraco-api";
import {
  isAIConversation,
  isContentUnit,
  isFormUnit,
  isMultiChoiceExercise,
  isOpenResponseExercise,
} from "../../../components/feature-units/unit.types";
import { themeBase } from "../../../theme";
import { ArrowLeft } from "@mui/icons-material";

interface SideBarProps {
  units: SidebarUnit[];
  unitsCompleted: number;
  unitsTotal: number;
  uniteId: number;
  featureTitle: string;
  feature: Feature;
  isMobile: Boolean;
}

const getIconForUnit = (unit: IUnit): string => {
  if (isFormUnit(unit)) return "📝"; // Clipboard
  if (isContentUnit(unit)) return "📘"; // Blue book for content
  if (isOpenResponseExercise(unit)) return "✒️"; // Fountain pen for writing
  if (isMultiChoiceExercise(unit)) return "🔢"; // Numbered for multiple choice
  if (isAIConversation(unit)) return "🤖"; // Robot for AI conversation
  return "📦"; // Package for default
};

const SideBar: React.FC<SideBarProps> = ({
  units,
  unitsCompleted,
  unitsTotal,
  uniteId,
  featureTitle,
  feature,
  isMobile,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const renderUnit = (unit: SidebarUnit, index: number, totalUnits: number) => {
    if (!unit.id) {
      console.warn("Unit ID is undefined:", unit);
      return null; // Safely handle undefined unit.id
    }
    const isCurrent = unit.id === uniteId; // Check if current
    const isPast = index < uniteId; // Check if past unit

    return (
      <Box
        key={`${index}_${unit.id}`}
        sx={{
          display: "flex",
          alignItems: "center",

          backgroundColor: themeBase.colorSchemes.new_design.palette.gray_light,

          // backgroundColor: isCurrent
          // ? themeBase.colorSchemes.new_design.palette.green
          // : isPast
          // ? themeBase.colorSchemes.new_design.palette.gray_light
          // : themeBase.colorSchemes.new_design.palette.gray_light,
          gap: 1,
          borderRadius: "12px",
          padding: "24px",
          overflowY: "auto",
        }}
      >
        {/* Icon for the Unit */}
        <Typography
          sx={{
            fontSize: "18px",
            color: themeBase.colorSchemes.new_design.palette.background,
            fontWeight: "bold",
          }}
        >
          {getIconForUnit(unit)}
        </Typography>

        {/* Unit Name */}
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: isCurrent
              ? themeBase.colorSchemes.new_design.palette.black
              : isPast
              ? themeBase.colorSchemes.new_design.palette.black
              : themeBase.colorSchemes.new_design.palette.gray_dark,
            flexGrow: 1,
          }}
        >
          {unit.name}
        </Typography>

        {/* Green Checkmark if Past */}
        {/* {isPast && (
          <CheckCircle
            sx={{
              color: themeBase.colorSchemes.new_design.palette.green_finish,
              fontSize: "25px",
            }}
          />
        )} */}

        {/* Play/Pause Button Placeholder */}
        {/* {isCurrent && (
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              color: themeBase.colorSchemes.new_design.palette.black,
            }}
          >
            ⏸️
          </Typography>
        )} */}
      </Box>
    );
  };

  const unitsListContent = (
    <Box
      sx={{
        width: { xs: "auto", md: "100%" },
        maxWidth: 250,
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
        padding: 1,
        overflowY: "auto", // Enable scrolling
      }}
    >
      <Box
        sx={{
          paddingY: "8px",
          borderBottom: "1px solid #E5E7EB",
          display: "flex",
          padding: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
            color: themeBase.colorSchemes.new_design.palette.black,
          }}
        >
          {featureTitle}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: themeBase.colorSchemes.new_design.palette.purple,
          }}
        >
          {unitsCompleted}/{unitsTotal}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor:
              themeBase.colorSchemes.new_design.palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${
                unitsTotal > 0 ? (unitsCompleted / unitsTotal) * 100 : 0
              }%`, // Fixed here
              height: "100%",
              backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>

      {/* Units List */}
      <Box
        sx={{
          paddingY: "14px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          // maxHeight: "400px", // Adjust the max height as needed
        }}
      >
        {units.map((unit, index) => renderUnit(unit, index, units.length))}
      </Box>
    </Box>
  );

  return isMobile ? (
    <>
      <Box
        onClick={() => setIsDrawerOpen(true)}
        sx={{
          height: "100%",
          backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
          borderStyle: "solid",
          justifyContent: "center",
          justifyItems: "center",
          display: "grid",
        }}
      >
        <IconButton
          sx={{
            borderRadius: 0,

            color: themeBase.colorSchemes.new_design.palette.purple,
          }}
        >
          <ArrowLeft
            sx={{
              borderRadius: 0,
              fontSize: "40px", // Adjust the font size as needed
            }}
          />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={{ padding: 2 }}>
          <IconButton
            onClick={() => setIsDrawerOpen(false)}
            sx={{ marginBottom: 2 }}
          >
            <CloseIcon />
          </IconButton>
          {unitsListContent}
        </Box>
      </Drawer>
    </>
  ) : (
    unitsListContent
  );
};

export default SideBar;
