import { CircularProgress, Box } from "@mui/joy";

const Loading = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="200px"
  >
    <CircularProgress />
  </Box>
);

export default Loading;
