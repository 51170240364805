import { LessonStatus } from "../store/api/umbraco-api";

export const getLessonStatus = (
    status: number | LessonStatus | undefined
  ): LessonStatus => {
    if (typeof status === "string") return status; // Already a LessonStatus
  
    const statusMapping: { [key: number]: LessonStatus } = {
      0: "NotStarted",
      1: "InProgress",
      2: "Completed",
    };
  
    return status !== undefined && statusMapping[status]
      ? statusMapping[status]
      : "NotStarted";
  };
  
// const statusMapping: { [key: number]: LessonStatus } = {
//   0: "NotStarted",
//   1: "InProgress",
//   2: "Completed",
// };

// // Ensure lessonData is defined and status is a valid number
