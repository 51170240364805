import { useCallback, useEffect, useState } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import { Send, Close, ChatBubble } from "@mui/icons-material";
import {
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
} from "../../store/api/umbraco-api";
import { debounce } from "lodash/fp";
import { themeBase } from "../../theme";
import { ASSETS } from "../../consts";
import MessageRenderer from "./message-renderer";
import { TypingIndicator } from "./TypingIndicator"; // Add TypingIndicator component

interface ChatProps {
  resourceId: number;
}

interface Message {
  sender: string;
  text: string;
}

const MobileChat = ({ resourceId }: ChatProps) => {
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChatAvailable, setIsChatAvailable] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false); // Add state for bot typing

  const [postChat] = usePostChatMutation();
  const [postChatByConversationIdMessages] =
    usePostChatByConversationIdMessagesMutation();

  const setup = async () => {
    try {
      setIsLoading(true);
      const response = await postChat({
        createConversationRequest: {
          initialMessage: "שלום",
          resourceId,
        },
      }).unwrap();

      setConversationId(response.conversationId || null);
      setMessages([
        { sender: "AI", text: response.message || "ברוך הבא לשיחה!" },
      ]);

      if (response.conversationId) setIsChatAvailable(true);
    } catch (err) {
      console.error("Error in setup:", err);
      setIsChatAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSetup = useCallback(debounce(500, setup), [resourceId]);

  useEffect(() => {
    if (resourceId) debouncedSetup();
  }, [resourceId, debouncedSetup]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { sender: "User", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsBotTyping(true); // Set typing indicator visible

    try {
      if (!conversationId) {
        const response = await postChat({
          createConversationRequest: { initialMessage: input, resourceId },
        }).unwrap();

        setConversationId(response.conversationId || null);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);
        if (response.conversationId) setIsChatAvailable(true);
      } else {
        const response = await postChatByConversationIdMessages({
          conversationId,
          createMessageRequest: { message: input },
        }).unwrap();

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);
      }
    } catch (err) {
      console.error("Error in message sending:", err);
    } finally {
      setIsBotTyping(false); // Hide typing indicator after receiving the response
    }
  };

  if (!isChatAvailable) return null;

  return (
    <>
      <IconButton
        onClick={() => setIsVisible((prev) => !prev)}
        sx={{
          position: "fixed",
          bottom: 16,
          padding: 0.3,
          borderRadius: 100,
          right: 16,
          zIndex: 1000,
          backgroundColor: isVisible
            ? themeBase.colorSchemes.new_design.palette.purple
            : themeBase.colorSchemes.new_design.palette.purple_light,

          color: isVisible
            ? themeBase.colorSchemes.new_design.palette.white
            : themeBase.colorSchemes.new_design.palette.purple,

          borderWidth: 1,
          borderColor: themeBase.colorSchemes.new_design.palette.purple,
          borderStyle: "solid",
          "&:hover": {
            color: themeBase.colorSchemes.new_design.palette.white,
            backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
            borderColor: "#fff", // Optional: Change border color on hover
          },
        }}
      >
        {isVisible ? (
          <Close sx={{ padding: 1, fontSize: "20px" }} />
        ) : (
          <ChatBubble sx={{ padding: 1, fontSize: "20px" }} />
        )}
      </IconButton>

      {isVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: 80,
            right: 16,
            width: "90%",
            maxWidth: "400px",
            height: "60%",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: "column",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              padding: "12px",
              borderBottom: "1px solid #e0e0e0",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <img
              src={ASSETS.AI_CHAT_IMAGE}
              alt="TAUGA AI"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            />
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                טאוגה
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                היעזר בטאוגה, עוזרת ההוראה מבינה מלאכותית{" "}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              padding: "16px",
              backgroundColor:
                themeBase.colorSchemes.new_design.palette.gray_extra_light,
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: "grid",
                  justifySelf:
                    message.sender === "AI" ? "flex-end" : "flex-start",
                  color:
                    message.sender === "User"
                      ? themeBase.colorSchemes.new_design.palette.purple
                      : themeBase.colorSchemes.new_design.palette.white,
                  borderRadius: "12px",
                  backgroundColor:
                    message.sender === "User"
                      ? themeBase.colorSchemes.new_design.palette.purple_light
                      : themeBase.colorSchemes.new_design.palette.purple,
                  padding: "10px 16px",
                  wordBreak: "break-word",
                  marginY: 1,
                }}
              >
                <Typography variant="body2">
                  <MessageRenderer text={message.text} />
                </Typography>
              </Box>
            ))}

            <Box
              sx={{
                display: "flex", // Ensure the container is a flexbox
                justifyContent: "flex-end", // Align to the left
                alignItems: "center", // Center vertically
                width: "100%", // Ensure full width
              }}
            >
              {isBotTyping && <TypingIndicator />}{" "}
              {/* Show typing indicator when bot is typing */}
            </Box>
          </Box>

          <Box
            sx={{
              padding: "12px",
              borderTop: "1px solid #e0e0e0",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              placeholder="כתוב הודעה..."
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
            <Button onClick={handleSendMessage}>
              <Send sx={{ transform: "rotate(180deg)" }} />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileChat;
