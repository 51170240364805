import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, LinearProgress } from "@mui/joy";
import { useMediaQuery } from "@mui/material";

import {
  LessonStatus,
  LessonUnit,
} from "../../../../store/api/umbraco-api";

import Stopper from "../../../../components/Stopper";
import Chat from "../../../../components/chat";
import MobileChat from "../../../../components/chat/mobile-chat";
import UnitSwitcher from "../../../../components/units/unit-switcher";
import LessonUnits from "./LessonUnits";
import { themeBase } from "../../../../theme";
import { getLessonStatus } from "../../../../utils/types";
import { LessonProvider } from "../../../../context/lesson-context";
import useLesson from "../../../../context/useLesson";
import { UnitTypes } from "../../../../components/feature-units/unit.types";

const LessonScreen = () => {
  const { isLoading, isError, lesson: lessonData, units: unitsData, currentUnitId, setCurrentUnitId, unitContent } = useLesson()

  const isMobile = useMediaQuery("(max-width:1000px)");

  const lessonStatus: LessonStatus = getLessonStatus(
    typeof lessonData?.status === "number" ? lessonData.status : 2
  );

  const selectedUnit = useMemo(() => {
    return unitsData.find(u => u.id === currentUnitId);
  }, [currentUnitId, unitsData]);

  const selectedUnitIndex = useMemo(() => {
    return unitsData.findIndex(u => u.id === currentUnitId);
  }, [currentUnitId, unitsData]);

  const handleNext = () => {
    if (unitsData && selectedUnitIndex < unitsData.length - 1) {
      const id = unitsData[selectedUnitIndex + 1].id;
      if (id) setCurrentUnitId(id);
    }
  };

  const handleBack = () => {
    if (selectedUnitIndex > 0) {
      const id = unitsData[selectedUnitIndex - 1].id;
      if (id) setCurrentUnitId(id);
    }
  };

  useEffect(() => {
    if (selectedUnitIndex < 0 && unitsData && unitsData.length > 0) {
      const firstIncomplete = unitsData.find((unit) => !unit.isComplete);
      if (firstIncomplete && firstIncomplete.id! > 0) {
        setCurrentUnitId(firstIncomplete.id!);
      } else {
        setCurrentUnitId(unitsData[0].id || null)
      }
    }
  }, [unitsData]);

  const isChat = useMemo(() => selectedUnit && (selectedUnit.type as unknown as UnitTypes) !== UnitTypes.AIConversation, [selectedUnit]);

  if (isLoading) { 
    return <LinearProgress />;
  }
  
  if (isError) {
    return <div>Error loading lesson or units</div>;
  }

  return (
    <Box sx={styles.container(isMobile)}>
      <Stopper />
      {unitsData && (
        <LessonUnits
          name={lessonData?.name || ""}
          description={lessonData?.description || ""}
          status={lessonStatus}
          setSelectedUnit={(unit: LessonUnit) => setCurrentUnitId(unit?.id || null)}
          totalUnits={lessonData?.totalUnits || 0}
          units={unitsData}
          completedUnits={lessonData?.completedUnits || 0}
          isMobile={isMobile}
          selectedUnit={selectedUnit || unitsData[0]}
        />
      )}
      
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            padding: 2,
            backgroundColor: themeBase.colorSchemes.new_design.palette.white,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
            borderStyle: "solid",
            overflowY: "auto",
          }}
        >
          {selectedUnit?.type !== undefined && (
          unitContent.isLoading ? <LinearProgress /> :
          <><UnitSwitcher
            unitId={selectedUnit.id!}
            unitType={selectedUnit.type} /><Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button onClick={handleBack} disabled={selectedUnitIndex === 0}>
                אחורה
              </Button>
              <Button
                onClick={handleNext}
                disabled={selectedUnitIndex === unitsData.length - 1}
              >
                קדימה
              </Button>
            </Box></>)}
        </Box>
      {isChat &&
        (isMobile ? (
          <MobileChat resourceId={selectedUnit?.id || 0} />
        ) : (
          <Chat resourceId={selectedUnit?.id || 0} />
        ))}
    </Box>
  );
};

const LessonScreenWithContext = () => {
  const { featureId } = useParams();
  const lessonId = featureId ? parseInt(featureId) : 0;

  return <LessonProvider lessonId={lessonId}>
    <LessonScreen />
  </LessonProvider>
}

export default LessonScreenWithContext;

const styles = {
  container: (isMobile: boolean) => ({
    display: "flex",
    height: "85vh",
    paddingX: 1,
    gap: 1,
    ...(isMobile && { paddingY: 2 }),
  }),
};