import { Box } from "@mui/joy";

interface ResponsiveLogoBoxProps {
  imageSrc: string;
}

const ResponsiveLogoBox = ({ imageSrc }: ResponsiveLogoBoxProps) => {
  return (
    <Box
      component="img"
      src={imageSrc}
      alt="logo"
      sx={{
        height: "90px",
        width: { xs: "90px", sm: "100px", md: "90px" }, // Adjusts size based on screen size
        objectFit: "contain",
      }}
    />
  );
};

export default ResponsiveLogoBox;
