import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { GetLessonsByLessonIdUnitsApiResponse, IUnitCompleted, LessonResponse, umbracoApi, useGetLessonsByIdQuery, useGetLessonsByLessonIdUnitsQuery, usePostLessonsByLessonIdUnitsAndIdMutation } from "../store/api/umbraco-api";
import { UnitContext } from "./unit-context";

interface LessonContext extends UnitContext {
  isLoading: boolean;
  isError: boolean;
  lesson: LessonResponse | undefined;
  units: GetLessonsByLessonIdUnitsApiResponse;
  currentUnitId: number | null;
  setCurrentUnitId: (id: number | null) => void;
}

export const LessonContext = createContext<LessonContext>({
  isLoading: true,
  isError: false,
  lesson: undefined,
  units: [],
  currentUnitId: null,
  setCurrentUnitId: () => null,
  unitContent: {
    isError: false,
    isLoading: true,
    data: undefined
  },
  unitCompletion: {
    isLoading: true,
    response: undefined,
    post: () => null,
  }
});


interface LessonProviderProps {
  lessonId: number;
};

export const LessonProvider = ({ lessonId, children }: PropsWithChildren<LessonProviderProps>) => {
  // Fetch lesson and units data
  const lessonState = useGetLessonsByIdQuery({ id: lessonId });
  const unitsState = useGetLessonsByLessonIdUnitsQuery({ lessonId });

  const [fetchUnitContent, unitContentState] = umbracoApi.useLazyGetLessonsByLessonIdUnitsAndUnitIdQuery();
  const [postUnitCompletion, unitCompletionState] = usePostLessonsByLessonIdUnitsAndIdMutation();
  
  const [currentUnitId, setCurrentUnitId] = useState<number | null>(null);

  // fetch unit content when unitId is changed
  useEffect(() => {
    if (currentUnitId) {
      fetchUnitContent({ lessonId, unitId: currentUnitId});
      unitCompletionState.reset();
    }
  }, [currentUnitId]);

  const handlePostUnitCompletion = (completion: IUnitCompleted) => {
    postUnitCompletion({ 
      lessonId, 
      id: completion.id || 0, 
      iUnitCompleted: completion 
    })
    .unwrap()
    .then(() => {
      lessonState.refetch();
      unitsState.refetch();
    })
    .catch(() => {
      console.error("Erro while posting unit completion: ", completion.id);
    });
  }

  return <LessonContext.Provider value={{
    isLoading: lessonState.isLoading || unitsState.isLoading,
    isError: lessonState.isError || unitsState.isError,
    lesson: lessonState.data,
    units: unitsState.data || [],
    currentUnitId, 
    setCurrentUnitId,
    unitContent: {
      isError: unitContentState.isError,
      isLoading: unitContentState.isUninitialized || unitContentState.isFetching,
      data: unitContentState.isFetching === false ? unitContentState.currentData : undefined,
    },
    unitCompletion: {
      isLoading: unitCompletionState.isLoading,
      response: unitCompletionState.data,
      post: handlePostUnitCompletion
    }
  }}>
    {children}
  </LessonContext.Provider>;
};