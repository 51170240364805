import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { debounce } from "lodash/fp";
import {
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
} from "../../store/api/umbraco-api";
import RichText from "../rich-text";
import { AIConversationExercise } from "./unit.types";
import MessageRenderer from "../chat/message-renderer";

interface AIConversationUnitProps {
  unit: AIConversationExercise;
  setUnitComplete(args: any): void;
  setUnitFinished(finish: boolean): void;
}

const AIConversationUnit = ({
  unit,
  setUnitComplete,
  setUnitFinished,
}: AIConversationUnitProps) => {
  const [messages, setMessages] = useState<
    Array<{ sender: string; text: string }>
  >([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [postChat] = usePostChatMutation();
  const [postChatByConversationIdMessages] =
    usePostChatByConversationIdMessagesMutation();

  useEffect(() => {
    if (conversationId) {
      setUnitFinished(true);
      setUnitComplete({ conversationId });
    }
  }, [setUnitFinished, setUnitComplete, conversationId]);

  const setupConversation = async () => {
    setIsLoading(true);

    try {
      const response = await postChat({
        createConversationRequest: {
          initialMessage: "INITIAL",
          resourceId: unit.id,
        },
      }).unwrap();
      setConversationId(response.conversationId || null);
      setMessages([
        { sender: "Tauga AI", text: response.message || "Initial response" },
      ]);
    } catch (err) {
      console.error("Error during setup:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSetup = debounce(500, setupConversation);

  useEffect(() => {
    if (unit.id) {
      debouncedSetup();
    }
  }, [unit.id]);

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "תלמיד", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      if (!conversationId) {
        const response = await postChat({
          createConversationRequest: {
            initialMessage: input,
            resourceId: unit.id,
          },
        }).unwrap();
        setConversationId(response.conversationId || null);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "Tauga AI", text: response.message || "" },
        ]);
      } else {
        const response = await postChatByConversationIdMessages({
          conversationId,
          createMessageRequest: { message: input },
        }).unwrap();
        console.log(response);
        // console.log(response)
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "Tauga AI", text: response.message || "" },
        ]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const config = {
    tex: {
      inlineMath: [["\\(", "\\)"]],
      displayMath: [["\\[", "\\]"]],
    },
  };
  // Function to render text with MathJax for equations

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {unit.title && (
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {unit.title}
        </Typography>
      )}
      <RichText content={unit.content} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          p: 3,
          backgroundColor: "#1e1e1e",
          borderRadius: "16px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            border: "1px solid #3f4147",
            borderRadius: "16px",
            padding: "16px",
            backgroundColor: "#2b2b2b",
            boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={
                message.sender === "תלמיד" ? "flex-start" : "flex-end"
              }
              sx={{ mb: 2 }}
            >
              <Box
                sx={{
                  maxWidth: "75%",
                  backgroundColor:
                    message.sender === "תלמיד" ? "#4a5568" : "#2d3748",
                  color: "#fff",
                  borderRadius: "16px",
                  padding: "12px 18px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", mb: 1, color: "#a0aec0" }}
                >
                  {message.sender}
                </Typography>
                <Typography variant="body1">
                  <MessageRenderer text={message.text} />
                </Typography>
              </Box>
            </Box>
          ))}
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <CircularProgress size={24} sx={{ color: "#a0aec0" }} />
            </Box>
          )}
        </Box>

        <Box display="flex" sx={{ mt: 3, gap: 1 }}>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                if (!isLoading) handleSendMessage();
              }
            }}
            placeholder="כתוב הודעה..."
            disabled={isLoading}
            sx={{
              backgroundColor: "#2d3748",
              borderRadius: "10px",
              input: { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "transparent" },
                "&:hover fieldset": { borderColor: "#4a5568" },
                "&.Mui-focused fieldset": { borderColor: "#718096" },
              },
            }}
            InputProps={{
              style: {
                paddingLeft: "16px",
                fontSize: "16px",
                color: "#FFF",
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            disabled={isLoading}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#4299e1",
              padding: "12px 16px",
              minWidth: "auto",
              ":hover": { backgroundColor: "#3182ce" },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : (
              <Send sx={{ transform: "rotate(-180deg)" }} />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AIConversationUnit;
