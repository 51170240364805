import { Card, Typography } from "@mui/joy";
import { IUnit, IUnitCompleted, LessonUnitContentResponse, useGetLessonsByLessonIdUnitsAndUnitIdQuery } from "../../../store/api/umbraco-api";
import RichText from "../../rich-text";
import { UnitProps } from "./../interfaces";
import Error from "./../shared/error";
import Loading from "./../shared/loading";
import ImageInputs from "./ImageInputs";
import TextInputs from "./TextInputs";
import { ImageAnswer, TextAnswer } from "./answer";
import useUnit from "../../../context/useUnit";
import { UnitContentResponse } from "../../../context/unit-context";
import { useEffect } from "react";


interface OpenExerciseQuestionResponse extends UnitContentResponse {
    unit: IUnit & {
        title: string;
        solution?: string | null;
        question: string;
        answerType: string;
    };
    completion: IUnitCompleted & { answer: string; };
}


const OpenExerciseQuestion = ({ unitId, unitType }: UnitProps) => {

    const { unitContent } = useUnit<OpenExerciseQuestionResponse>();

    if (unitContent.isLoading || !unitContent.data || unitContent.data.unit.id !== unitId) {
        return <Loading />;
    }

    if (unitContent.isError) {
        return <Error />;
    }

    const { unit, isCompleted, completion } = unitContent.data;

    return (
        <>
            {unit.title && <Typography level="title-lg">{unit.title}</Typography>}
            <RichText content={unit.question} />
            {!isCompleted &&
                (unit.answerType === "image"
                    ? <ImageInputs unitId={unitId} unitType={unitType} solution={unit.solution} />
                    : <TextInputs unitId={unitId} unitType={unitType} solution={unit.solution} />
                )
            }
            {isCompleted && 
                (unit.answerType === "image" 
                ? <ImageAnswer imageUrls={completion.answer.split(',')} solution={unit.solution}/>
                : <TextAnswer text={completion.answer} solution={unit.solution}/>
                )
            }
        </>
    );
}

export default OpenExerciseQuestion;
