import { Outlet } from "react-router-dom";
import { Box } from "..";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/session/selectors";
import { useEffect } from "react";
import useNavigateWithProvider from "../../hooks/useNavigateWithProvider";

const UnauthenticatedRoute = () => {
  const user = useSelector(userSelector);

  useEffect(() => {
    console.log("User from Redux:", user);  // Log user state
  }, [user]);
  
  

  const navigateWithProvider = useNavigateWithProvider();
  useEffect(() => {
    if (user) {
      navigateWithProvider("/");
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default UnauthenticatedRoute;
