import { Box } from "..";
import Typography from "../typography";
import { themeBase } from "../../theme";
import { Feature } from "../../store/api/umbraco-api";
import { Link } from "react-router-dom";
import { FeatureWithNumericType } from "../../screens/course";

/**
 * TODO: Type Fix Needed
 * There's currently a mismatch between the API types and runtime values:
 * - API expects FeatureType to be string enum ("SmartExercise" | "Lesson" | "SmartLesson")
 * - But we actually receive numbers (0, 1, 2, 3)
 *
 * This should be fixed in one of two ways:
 * 1. Update the API types to match the actual numeric values
 * 2. Transform the numeric values to match the API types
 *
 * For now, we're using type assertions as a temporary solution.
 */

// Add this type to bridge the gap between API types and actual values

interface ChapterCardProps {
  id: number;
  title?: string;
  total?: number;
  completed?: number;
  learningMinutes?: number;
  features?: FeatureWithNumericType[]; // Use the updated Feature type
}



const ChapterCard = ({
  title = "Untitled",
  id = 0,
  total = 0,
  completed = 0,
  features = [],
}: ChapterCardProps) => {
  const getFeaturePath = () => {
    if (!features.length || features[0]?.type === undefined) {
      return `chapter/${id}/lesson/0`;
    }

    const featureType = features[0].type;
    if (featureType === 0) {
      return `/chapter/${id}/exercise/${features[0].id}`;
    } else if (featureType === 1) {
      return `/chapter/${id}/lesson/${features[0].id}`;
    } else if (featureType === 2) {
      return `/chapter/${id}/smart-lesson/${features[0].id}`;
    }
    return "/404";
  };


  return (
    <Link
      style={{ textDecoration: "none" }}
      state={{
        features: features as unknown as Feature[],
      }}
      to={getFeaturePath()}
    >
      <Box
        sx={{
          minHeight: "60px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3,
          borderRadius: "12px",
          bgcolor: themeBase.colorSchemes.new_design.palette.white,
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: 500,
              color: themeBase.colorSchemes.new_design.palette.black,
            }}
          >
           {sm}
          </Typography>
          {featureIcon}
        </Box> */}
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: 700,
            color: themeBase.colorSchemes.new_design.palette.black,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: themeBase.colorSchemes.new_design.palette.purple,
            }}
          >
            {completed}/{total}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "10px",
            backgroundColor:
              themeBase.colorSchemes.new_design.palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${total > 0 ? (completed / total) * 100 : 0}%`,
              height: "100%",
              backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>
    </Link>
  );
};

export default ChapterCard;
