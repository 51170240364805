import { Typography } from "@mui/joy";
import RichText from "../rich-text";
import { UnitProps } from "./interfaces";
import { IUnit } from "../../store/api/umbraco-api";
import Loading from "./shared/loading";
import Error from "./shared/error";
import FinishButton from "./shared/btn";
import useElapsedTime from "../../hooks/useElapsedTime";
import useUnit from "../../context/useUnit";
import { useEffect } from "react";
import { UnitContentResponse } from "../../context/unit-context";

export interface ContentUnitData extends UnitContentResponse {
  unit: IUnit & {
    title: string;
    content: string;
  };
}

const ContentUnit = ({ unitId }: UnitProps) => {
  const { unitContent, unitCompletion } = useUnit<ContentUnitData>();

  const fetchTime = useElapsedTime(); // Use the custom hook to track the start time

  const handleFinishUnit = async () => {
    unitCompletion.post({
        id: unitId,
        type: "ContentUnit", // or the correct type for your unit
        start: fetchTime.toISOString(), // fetchTime is guaranteed to be a Date
        end: new Date().toISOString(),
      })
  };

  if (!unitContent.data || unitContent.isLoading) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit, isCompleted } = unitContent.data;
  return (
    <>
      {unit?.title && (
        <Typography variant="plain" gutterBottom>
          {unit.title}
        </Typography>
      )}
      {unit?.content && <RichText content={unit.content} />}
      {!isCompleted && (
        <FinishButton title="סיימתי לקרוא" disabled={unitCompletion.response !== undefined} onClick={handleFinishUnit} />
      )}
    </>
  );
};

export default ContentUnit
