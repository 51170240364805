import React, { ReactNode } from "react";
import { Box, Typography, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowLeft, Done } from "@mui/icons-material";
import { themeBase } from "../../../../theme";
import { LessonUnit, LessonResponse } from "../../../../store/api/umbraco-api";

interface LessonUnitsProps extends Omit<LessonResponse, "id"> {
  units?: LessonUnit[];
  isMobile?: boolean;
  setSelectedUnit: (unit: LessonUnit) => void;
  selectedUnit: LessonUnit;
}

const LessonUnits = ({
  name,
  totalUnits = 0,
  completedUnits = 0,
  units = [],
  setSelectedUnit,
  selectedUnit,
}: LessonUnitsProps) => {
    return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 250,
        backgroundColor: themeBase.colorSchemes.new_design.palette.white,
        borderRadius: "16px",
        padding: 1,
        overflowY: "auto",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          paddingY: "8px",
          borderBottom: "1px solid #E5E7EB",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
            color: themeBase.colorSchemes.new_design.palette.black,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: themeBase.colorSchemes.new_design.palette.purple,
          }}
        >
          {completedUnits}/{totalUnits}
        </Typography>
      </Box>

      {/* Progress Bar */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor:
              themeBase.colorSchemes.new_design.palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${
                totalUnits > 0 ? (completedUnits / totalUnits) * 100 : 0
              }%`,
              height: "100%",
              backgroundColor: themeBase.colorSchemes.new_design.palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>

      {/* Units List */}
      <Box
        sx={{
          paddingY: "14px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {units.map(unit => <UnitListItem key={unit.id} unit={unit} isCurrent={unit.id === selectedUnit.id} onClick={() => setSelectedUnit(unit)}/>)}
      </Box>
    </Box>
  );
};

interface UnitListItemProps {
  unit: LessonUnit;
  isCurrent: boolean;
  onClick: () => void;
}
const UnitListItem = ({ unit, isCurrent, onClick } : UnitListItemProps) => {
  return (
    <Box
      key={unit.id}
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: isCurrent
          ? themeBase.colorSchemes.new_design.palette.green // Highlight current unit
          : themeBase.colorSchemes.new_design.palette.background,
        borderRadius: "12px",
        padding: "24px",
        cursor: "pointer",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          color: themeBase.colorSchemes.new_design.palette.black,
          flexGrow: 1,
        }}
      >
        {unit.name}
      </Typography>
      {unit.isComplete && <Done htmlColor="#8280FF"/>}
    </Box>
  );
};

const MobileDrawer = ({ children } : { children: ReactNode}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  return <>
  <Box
    onClick={() => setIsDrawerOpen(true)}
    sx={{
      height: "100%",
      backgroundColor: themeBase.colorSchemes.new_design.palette.white,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
      borderStyle: "solid",
      justifyContent: "center",
      display: "grid",
    }}
  >
    <IconButton
      sx={{
        borderRadius: 0,
        color: themeBase.colorSchemes.new_design.palette.purple,
      }}
    >
      <ArrowLeft sx={{ fontSize: "40px" }} />
    </IconButton>
  </Box>

  <Drawer
    anchor="right"
    open={isDrawerOpen}
    onClose={() => setIsDrawerOpen(false)}
  >
    <Box sx={{ padding: 2 }}>
      <IconButton
        onClick={() => setIsDrawerOpen(false)}
        sx={{ marginBottom: 2 }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Box>
  </Drawer>
</>
}

const LessonSidebar = ({ isMobile = false, ...props } : LessonUnitsProps) => {
  if (isMobile) {
    return <MobileDrawer><LessonUnits {...props}/></MobileDrawer>;
  }

  return <LessonUnits {...props}/>;
}

export default LessonSidebar;

