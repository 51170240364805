import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
  IUnitCompleted,
  umbracoApi
} from "../../store/api/umbraco-api";
import RichText from "../rich-text";
import { UnitProps } from "./interfaces";
import { AIConversationExercise } from "../feature-units/unit.types";
import Error from "./shared/error";
import Loading from "./shared/loading";
import { themeBase } from "../../theme";
import useUnit from "../../context/useUnit";
import { UnitContentResponse } from "../../context/unit-context";

enum Sender {
  AI = "Tauga AI",
  Student = "תלמיד",
}

interface Message {
  sender: Sender;
  text: string;
}

interface AIConversationResponse extends UnitContentResponse {
  unit: AIConversationExercise;
  completion: IUnitCompleted & { conversationId: string }
}

const AIConversationUnit = ({ unitId }: UnitProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);

  const { unitContent, unitCompletion } = useUnit<AIConversationResponse>();

  const [fetchChatMessages, chatMessages] = umbracoApi.useLazyGetChatByConversationIdMessagesQuery();
  const [postChat, { isLoading: chatIsLoading }] = usePostChatMutation();
  const [postChatByConversationIdMessages, { isLoading: messageIsLoading }] =usePostChatByConversationIdMessagesMutation();

  // conversation setup 
  useEffect(() => {
    if (conversationId || !unitContent.data || unitContent.data.unit.id !== unitId || unitCompletion.response) return;
    if (unitContent.data?.isCompleted && unitContent.data.completion.conversationId) {
      setConversationId(unitContent.data.completion.conversationId);
      fetchChatMessages({ conversationId: unitContent.data.completion.conversationId });
    } else {
      postChat({
        createConversationRequest: {
          initialMessage: "INITIAL",
          resourceId: unitId,
        },
      })
      .unwrap()
      .then(response => {
        setConversationId(response.conversationId || null);
        setMessages([
          { sender: Sender.AI, text: response.message || "Initial response" },
        ]);
        unitCompletion.post({
          id: unitId,
          type: "AIConversation",
          start: new Date().toISOString(),
          end: new Date().toISOString(),
          conversationId: response.conversationId
        } as IUnitCompleted,
      );
      })
      .catch(err => console.error("Error during setup:", err))
    }
  }, [unitContent, unitCompletion]);

  useEffect(() => {
    if (chatMessages.isSuccess) {
      if (chatMessages.data.length >= 1) {
        setMessages(chatMessages.data.slice(1).map(m => ({ sender: m.sender as unknown as number === 0 ? Sender.AI : Sender.Student, text: m.content || "" })));
      }
    }
  }, [chatMessages]);

  const handleSendMessage = async () => {
    if (!input.trim() || !conversationId) return;

    const userMessage: Message = { sender: Sender.Student, text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");

    try {
      const response = await postChatByConversationIdMessages({
        conversationId,
        createMessageRequest: { message: input },
      }).unwrap();
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: Sender.AI, text: response.message || "" },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    };
  }

  if (!unitContent.data || unitContent.isLoading || chatIsLoading) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit } = unitContent.data as AIConversationResponse;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {unit.title && (
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {unit.title}
        </Typography>
      )}
      <RichText content={unit.content} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          p: 3,
          backgroundColor: "#1e1e1e",
          borderRadius: "16px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            border: "1px solid #3f4147",
            borderRadius: "16px",
            padding: "16px",
            backgroundColor: "#2b2b2b",
            boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={
                message.sender === Sender.Student ? "flex-start" : "flex-end"
              }
              sx={{ mb: 2 }}
            >
              <Box
                sx={{
                  maxWidth: "75%",
                  backgroundColor:
                    message.sender === Sender.Student ? "#4a5568" : "#2d3748",
                  color: "#fff",
                  borderRadius: "16px",
                  padding: "12px 18px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "500", mb: 1, color: "#a0aec0" }}
                >
                  {message.sender}
                </Typography>
                <Typography variant="body1">
                {message.text.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
                </Typography>
              </Box>
            </Box>
          ))}
          {messageIsLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <CircularProgress size={24} sx={{ color: "#a0aec0" }} />
            </Box>
          )}
        </Box>

        <Box display="flex" sx={{ mt: 3, gap: 1 }}>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                if (!messageIsLoading) handleSendMessage();
              }
            }}
            placeholder="כתוב הודעה..."
            disabled={messageIsLoading}
            sx={{
              backgroundColor: "#2d3748",
              borderRadius: "10px",
              input: { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "transparent" },
                "&:hover fieldset": { borderColor: "#4a5568" },
                "&.Mui-focused fieldset": { borderColor: "#718096" },
              },
            }}
            InputProps={{
              style: {
                paddingLeft: "16px",
                fontSize: "16px",
                color: themeBase.colorSchemes.new_design.palette.white,
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            disabled={messageIsLoading}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#4299e1",
              padding: "12px 16px",
              minWidth: "auto",
              ":hover": { backgroundColor: "#3182ce" },
            }}
          >
            {messageIsLoading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : (
              <Send sx={{ transform: "rotate(-180deg)" }} />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AIConversationUnit;
