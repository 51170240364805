import { Button } from "@mui/joy";

interface FinishButtonProps {
  onClick: () => void;
  title: string;
  disabled?: boolean;
}

const FinishButton = ({ onClick, title, disabled }: FinishButtonProps) => (
  <Button 
    onClick={onClick} 
    variant="outlined" 
    color="success" 
    sx={{ mt: 2 }}
    disabled={disabled}
  >
    {title}
  </Button>
);

export default FinishButton;
