import { UnitContentResponse, UnitContext } from "./unit-context";
import useLesson from "./useLesson";

const useUnit = <TData extends UnitContentResponse = UnitContentResponse,>() : UnitContext<TData> => {
    const lessonContext = useLesson();

    if (lessonContext) return lessonContext as unknown as UnitContext<TData>;

    throw new Error("No context");    
};

export default useUnit;