import { UnitTypes } from "../feature-units/unit.types";
import AIConversation from "./ai-conversation";
import ContentUnit from "./content-unit";
import { UnitProps } from "./interfaces";
import MultiChoiseExreciseUnit from "./multi-choise-exrecise";
import OpenExerciseQuestion from "./open-exercise-question";

export default function UnitSwitcher({ ...props }: UnitProps) {
  const renderUnit = () => {
    switch (props.unitType as unknown as UnitTypes) {
      case UnitTypes.ContentUnit:
        return <ContentUnit {...props} />;
      case UnitTypes.OpenResponseExercise:
        return <OpenExerciseQuestion {...props} />;
      case UnitTypes.MultiChoiceExercise:
      return <MultiChoiseExreciseUnit {...props} />;
      case UnitTypes.AIConversation:
        return <AIConversation {...props} />;
      default:
        return <div>No matching component</div>;
    }
  };

  return <>{renderUnit()}</>;
}
