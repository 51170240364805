import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  umbracoApi,
  usePostFeaturesByFeatureIdMutation,
  usePostFeaturesByFeatureIdUnitsAndUnitIdSkipMutation,
} from "../../../store/api/umbraco-api";
import { Box, Button, Typography, LinearProgress } from "@mui/joy";
import { themeBase } from "../../../theme";

import FeatureFinished from "./feature-finished";
import { isAIConversation } from "../../../components/feature-units/unit.types";
import Unit from "../../../components/feature-units/unit";
import SideBar from "./side-bar";
import useNavigateWithProvider from "../../../hooks/useNavigateWithProvider";
import { useMediaQuery } from "@mui/material";
import Stopper from "../../../components/Stopper";
import MobileChat from "../../../components/chat/mobile-chat";
import Chat from "../../../components/chat";

const FeatureScreen = () => {
  const navigateWithProvider = useNavigateWithProvider();
  const { featureId } = useParams();

  const [unitFinished, setUnitFinished] = useState(false);
  const isMobile = useMediaQuery("(max-width:1000px)");

  const [unitCompleteObject, setUnitCompleteObject] = useState({});
  const [fetch, { data, isFetching, isError }] =
    umbracoApi.useLazyGetFeaturesByFeatureIdQuery();
  const [fetchUnits, { data: units }] =
    umbracoApi.useLazyGetFeaturesByFeatureIdUnitsQuery();
  const [completeUnit, { isSuccess: isPostSuccess }] =
    usePostFeaturesByFeatureIdMutation();
  const [skipUnit, { isSuccess: isSkipSuccess }] =
    usePostFeaturesByFeatureIdUnitsAndUnitIdSkipMutation();

  const postUnitComplete = useCallback(() => {
    completeUnit({
      featureId: featureId as unknown as number,
      iUnitCompleted: unitCompleteObject,
    });
  }, [featureId, completeUnit, unitCompleteObject]);

  const postSkipUnit = useCallback(() => {
    if (data?.unit?.id) {
      skipUnit({
        featureId: featureId as unknown as number,
        unitId: data?.unit?.id,
      });
    }
  }, [data?.unit?.id, featureId, skipUnit]);

  useEffect(() => {
    fetch({ featureId: parseInt(featureId as string) });
    fetchUnits({ featureId: parseInt(featureId as string) });
  }, [featureId, fetch, fetchUnits]);

  useEffect(() => {
    if (isPostSuccess || isSkipSuccess) {
      fetch({ featureId: parseInt(featureId as string) });
      fetchUnits({ featureId: parseInt(featureId as string) });
    }
  }, [isPostSuccess, isSkipSuccess, fetch, featureId, fetchUnits]);

  if (isError) {
    navigateWithProvider("/404");
  }

  if (isFetching) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  if (!data || !data.feature) {
    return <Typography>שגיאה בלתי צפויה</Typography>;
  }

  const { feature } = data;
  const { unitsCompleted = 0, unitsTotal = 1 } = feature;

  return (
    <Box
      sx={{
        display: "flex",
        height: "85vh",
        paddingX: 1,
        gap: 1,
        ...(isMobile && { paddingY: 2 }), // Add padding only if it's mobile
      }}
    >
      <Stopper />
      {units && (
        <SideBar
          isMobile={isMobile}
          feature={feature}
          featureTitle={data?.feature?.name || ""}
          uniteId={data.unit?.id || 0}
          units={units || []}
          unitsCompleted={unitsCompleted}
          unitsTotal={unitsTotal}
        />
      )}
      <Box
        sx={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
          padding: 2,
          backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          borderRadius: 20,
          borderWidth: 1,
          borderColor: themeBase.colorSchemes.new_design.palette.gray_light,
          borderStyle: "solid",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: themeBase.colorSchemes.new_design.palette.white,
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {unitsCompleted === unitsTotal || !data.unit ? (
            <FeatureFinished />
          ) : (
            <Unit
              skip={postSkipUnit}
              setUnitFinished={setUnitFinished}
              onUnitCompleteChange={setUnitCompleteObject}
              unit={data.unit!} // Use non-null assertion
            />
          )}
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {data.unit && unitFinished && (
              <Button
                sx={{
                  marginTop: 2,
                  alignSelf: "flex-start",
                  backgroundColor: "#6200ea",
                  color: "white",
                }}
                onClick={postUnitComplete}
              >
                המשך
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {data?.unit?.id && !isAIConversation(data.unit) && (
        <>
          {isMobile ? (
            <MobileChat resourceId={data.unit.id} />
          ) : (
            <Chat resourceId={data.unit.id} />
          )}
        </>
      )}
    </Box>
  );
};

export default FeatureScreen;
