import { Typography } from "@mui/joy";
import RichText from "../../rich-text";
interface ContentUnitCompletedProps {
  title: string;
  content: string;
}

const ContentUnitCompleted = ({ title, content }: ContentUnitCompletedProps) => (
  <>
      {title && <Typography>{title}</Typography>}
      <RichText content={content} />
  </>
);

export default ContentUnitCompleted;