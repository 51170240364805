import { Box, Typography } from "@mui/joy";
import { useEffect } from "react";
import useNavigateWithProvider from "../../hooks/useNavigateWithProvider";
import { useParams } from "react-router-dom";

const ChapterScreen = () => {
  const { chapterId } = useParams();
  const navigateWithProvider = useNavigateWithProvider();


  useEffect(() => {
    navigateWithProvider(`chapter${chapterId}/featuer`);
  }, []);



  return (
    <Box>
      <Typography>teset</Typography>
    </Box>
  );
};

export default ChapterScreen;
