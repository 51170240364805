import { Typography, RadioGroup, FormLabel, Card, Radio } from "@mui/joy";
import RichText from "../../rich-text";

interface ExerciseQuestionCopmletedProps {
    title: string;
    question: string;
    answers: string[];
    solution?: string | null;
    isAnswered: boolean;
    isCorrectAnswer: boolean;
    setChoice: React.Dispatch<React.SetStateAction<number | null>>
    choice: number;
}

const ExerciseQuestionCompleted = ({
    title,
    question,
    solution,
    isAnswered,
    isCorrectAnswer,
    answers,
    choice,
    setChoice
}: ExerciseQuestionCopmletedProps) => (
  <>
    {title && <Typography level="title-lg">{title}</Typography>}

      <RichText content={question} />
      <RadioGroup name="answers" sx={{ gap: 1 }}>
            {answers.map((ans, index) => (
            
          <span key={ans}>
            <FormLabel>
              <Card sx={{ flexDirection: "row" }}>
                        <Radio
                            value={index}
                    checked={choice === index}
                    onChange={() => setChoice(index)}
                    key={index}
                    disabled={isAnswered}
                />
                <RichText content={ans} />
              </Card>
           </FormLabel>
          </span>
        ))}
      </RadioGroup>

      {isAnswered && (
        <Card>
          {isCorrectAnswer ? (
            <Typography color="primary">תשובה נכונה</Typography>
          ) : (
            <Typography color="danger">הפעם טעית</Typography>
          )}

          {solution && <RichText content={solution} />}
        </Card>
    )}
  </>
);

export default ExerciseQuestionCompleted;