import { useState, useEffect } from "react";

const useElapsedTime = (): Date => {
  const [startTime] = useState(new Date()); // Initialize with a Date object

  useEffect(() => {
    return () => {
      const endTime = new Date();
      console.log(`Time spent: ${endTime.getTime() - startTime.getTime()} ms`);
    };
  }, [startTime]);

  return startTime; // Always return a valid Date
};

export default useElapsedTime;
