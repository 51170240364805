import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { MuiMarkdown } from "mui-markdown";

interface MessageRendererProps {
  text: string;
}

const MessageRenderer: React.FC<MessageRendererProps> = ({ text }) => {
  // Regex to identify inline (\(...\)) and block (\[...\]) LaTeX equations
  // @ts-ignore
  const latexRegex = /(\\\[.*?\\\]|\\\(.*?\\\))/s;
  

  // Split the text into parts: LaTeX and non-LaTeX
  const parts = text.split(latexRegex);

  return (
    <MathJaxContext>
      {parts.map((part, index) => {
        if (latexRegex.test(part)) {
          // Ensure LaTeX content is wrapped correctly and safely
          const trimmedPart = part.trim(); // Trim spaces for cleaner rendering
          const isInline = trimmedPart.startsWith("\\(") && trimmedPart.endsWith("\\)");
          return (
            <MathJax key={index} inline={isInline}>
              {trimmedPart}
            </MathJax>
          );
        }

        return <MuiMarkdown
          key={index}
          overrides={{
            code: {
              component: (props) => <code {...props} />,
            },
          }}
        >
          {part}
        </MuiMarkdown>;
      })}
    </MathJaxContext>
  );
};

export default MessageRenderer;
