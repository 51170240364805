import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  provider: null, // Initially no provider
};

const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload; // Update provider
    },
  },
});

export const { setProvider } = providerSlice.actions;
export default providerSlice.reducer;
