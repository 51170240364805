import { useDispatch, useSelector } from "react-redux";
import { Box, Button, List, ListItem } from "@mui/joy";
import { ArrowDropDown, Logout, Person } from "@mui/icons-material";
import Avatar from "../avatar";
import { persistor } from "../../store";
import { Dropdown, Menu, Typography } from "@mui/joy";
import { MenuButton } from "@mui/joy";
import { logout } from "../../store/session/actions/user.actions";
import {
  useGetLoginProvidersByProviderUrlNameQuery,
  useGetOrganizationsQuery,
  usePostAuthLogoutMutation,
} from "../../store/api/umbraco-api";
import { Link, useNavigate } from "react-router-dom";
import { providerSelector, userSelector } from "../../store/session/selectors";
import { TAUGA_AI_LOGO_1 } from "../../images/images";
import { useEffect, useState } from "react";
import ResponsiveLogoBox from "../ResponsiveLogoBox";
import { themeBase } from "../../theme";
import useNavigateWithProvider from "../../hooks/useNavigateWithProvider";
import { setSelectedCourse } from "../../store/courseSlice";

interface LoginProvider {
  logoUrl?: string[];
}

interface LogosRowProps {
  loginProvider?: LoginProvider;
}

const LogosRow = ({ loginProvider }: LogosRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto", // Smooth horizontal scrolling
        whiteSpace: "nowrap", // Prevent wrapping of logos
        gap: 1, // Gap between logos
        alignItems: "center",
        paddingY: 1, // Slight vertical padding for better spacing
        maxWidth: "100%", // Prevent it from growing outside the container
        scrollbarWidth: "none", // Hide scrollbar in Firefox
        "-ms-overflow-style": "none", // Hide scrollbar in IE/Edge
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar in Chrome/Safari
        },
      }}
    >
      {loginProvider?.logoUrl?.map((logo, index) => (
        <Box
          key={index}
          component="img"
          src={logo}
          alt={`Login Provider Logo ${index}`}
          sx={{
            width: { xs: "40px", sm: "50px", md: "60px" }, // Responsive sizes
            height: "auto", // Maintain aspect ratio
            objectFit: "contain",
            borderRadius: "5%", // Slight rounded corners
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)", // Subtle shadow
            backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          }}
        />
      ))}
    </Box>
  );
};

const Header = () => {
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const [mutate] = usePostAuthLogoutMutation();
  const dispatch = useDispatch();
  const provider = useSelector(providerSelector);

  const navigateWithProvider = useNavigateWithProvider();

  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [courses, setCourses] = useState<{ id: number; name: string }[]>([]);

  const { data } = useGetOrganizationsQuery();
  const { data: loginProvider } = useGetLoginProvidersByProviderUrlNameQuery({
    providerUrlName: (provider as string) || "",
  });

  // Extract all courses from organizations
  useEffect(() => {
    if (data) {
      const allCourses = data.flatMap(
        (org) =>
          org.courses
            ?.map((course) => ({
              id: course.id,
              name: course.name,
            }))
            .filter(
              (course): course is { id: number; name: string } =>
                course.id !== undefined && course.name !== undefined
            ) || []
      );

      setCourses(allCourses);

      // Set the first course as default selected
      if (allCourses.length > 0) {
        const firstCourse = allCourses[0];
        setSelectedItem(firstCourse.name);
        dispatch(setSelectedCourse(firstCourse));
      }
    }
  }, [data, dispatch]);

  const toggleListVisibility = () => setIsListVisible((prev) => !prev);

  const handleItemClick = (course: { id: number; name: string }) => {
    dispatch(setSelectedCourse(course));
    setSelectedItem(course.name);
    setIsListVisible(false);
    navigate("/");
  };

  const onPressLogout = async () => {
    try {
      await mutate().unwrap();
      dispatch(logout());
      persistor.purge();
      navigateWithProvider("login");
    } catch (error) {
      // console.log(error);
    }
  };

  return (

      <Box
        sx={{
          backgroundColor: themeBase.colorSchemes.new_design.palette.white,
          paddingX: {
            xs: "6px",
            sm: "20px",
          },
          paddingY: { xs: "2px", sm: "4px" },
        }}
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={`1px solid ${themeBase.colorSchemes.new_design.palette.gray_light}`}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <LogosRow loginProvider={loginProvider} />
          </Box>
          {user && (
            <Dropdown>
              <MenuButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  sx={{
                    color: themeBase.colorSchemes.new_design.palette.purple,
                    backgroundColor:
                      themeBase.colorSchemes.new_design.palette.purple_light,
                  }}
                >
                  <Person />
                </Avatar>
                <Box
                  sx={{
                    textAlign: "right",
                    display: { xs: "none", md: "block" }, // Hidden on xs and sm, visible on md and larger
                  }}
                >
                  <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                    {user.firstName.charAt(0).toUpperCase() +
                      user.firstName.slice(1)}{" "}
                    {user.lastName.charAt(0).toUpperCase() +
                      user.lastName.slice(1)}{" "}
                  </Typography>

                  {/* <Typography level="body-sm" color="neutral">
                    {user.isTeacher ? "מורה" : "תלמיד"}
                  </Typography> */}
                </Box>
              </MenuButton>
              <Menu
                sx={{
                  textAlign: "center",
                  padding: "12px",
                  minWidth: "150px",
                  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              >
                <Box
                  onClick={onPressLogout}
                  sx={{
                    color: "error.main",
                    fontWeight: "bold",
                    justifyContent: "start",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    borderRadius: 2,
                    padding: "8px 12px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Logout sx={{ fontSize: 20 }} />
                  התנתק
                </Box>
              </Menu>
            </Dropdown>
          )}
        </Box>

        {/* New Course List Dropdown */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {courses.length > 1 && (
            <Box sx={{ position: "relative" }}>
              <Button
                onClick={toggleListVisibility}
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  backgroundColor:
                    themeBase.colorSchemes.new_design.palette.purple,
                  color: "white",
                  padding: "10px",
                  fontWeight: "bold",
                  borderRadius: 8,
                  fontSize: 12,
                  marginTop: 1,
                  transition: "all 200ms ease-in-out",
                  "&:hover": {
                    backgroundColor:
                      themeBase.colorSchemes.new_design.palette.purple_dark,
                    color: "lightgray",
                  },
                  "&:disabled": {
                    backgroundColor:
                      themeBase.colorSchemes.new_design.palette.purple,
                    color: themeBase.colorSchemes.new_design.palette.white,
                  },
                }}
              >
                <ArrowDropDown />
                <Box
                  component="span"
                  sx={{
                    display: { xs: "inline", sm: "none" }, // Show on mobile only
                  }}
                >
                  {selectedItem
                    ? selectedItem.length > 20
                      ? `${selectedItem.slice(0, 20)}...`
                      : selectedItem
                    : "Select Course"}
                </Box>
                <Box
                  component="span"
                  sx={{
                    display: { xs: "none", sm: "inline" }, // Show full text on larger screens
                  }}
                >
                  {selectedItem || "Select Course"}
                </Box>
              </Button>

              {isListVisible && (
                <List
                  sx={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    backgroundColor:
                      themeBase.colorSchemes.new_design.palette.white,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    zIndex: 10,
                    padding: "8px 0",
                    minWidth: "150px",
                  }}
                >
                  {courses.map((course) => (
                    <ListItem
                      key={course.id}
                      onClick={() => handleItemClick(course)}
                      sx={{
                        cursor: "pointer",
                        padding: "8px 12px",
                        "&:hover": {
                          backgroundColor:
                            themeBase.colorSchemes.new_design.palette
                              .gray_light,
                        },
                      }}
                    >
                      {course.name}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          )}
          <Link
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "grid",
            }}
            to="/"
          >
            <ResponsiveLogoBox imageSrc={TAUGA_AI_LOGO_1} />
          </Link>
        </Box>
      </Box>
  );
};

export default Header;
