import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { get } from "lodash/fp";
import { logout } from "../session/actions/user.actions";
import { baseApi } from "./base-api";

const UNAUTHORIZED_CODE = 401;

export const authenticationMIddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    //invalidate redux toolkit api cache on logout
    if (logout.match(action)) {
      dispatch(baseApi.util.resetApiState());
    }

    if (isRejectedWithValue(action)) {
      const urlAuthCheck = get("meta.baseQueryMeta.response.url", action);

      if (
        urlAuthCheck &&
        (urlAuthCheck as string).includes("/Account/Login?ReturnUrl=")
      ) {
        dispatch(logout());
      }

      if (get("originalStatus", action.payload) === UNAUTHORIZED_CODE) {
        dispatch(logout());
      }
    }

    return next(action);
  };
