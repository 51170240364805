import { Typography, Box } from "@mui/material";

const Error = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="200px"
  >
    <Typography color="error" fontSize="lg">
      Something went wrong. Please try again later.
    </Typography>
  </Box>
);

export default Error;
