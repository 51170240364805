import { useState, useEffect, useRef, useCallback } from "react";
import Draggable from "react-draggable";
import { Box, IconButton, Typography } from "@mui/joy";
import {
  Close,
  PlayArrow,
  Stop,
  RestartAlt,
  Timelapse,
} from "@mui/icons-material";
import { themeBase } from "../theme";
import {
  incrementTime,
  resetTimer,
  startTimer,
  stopTimer,
} from "../store/timerSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { isMobile } from "react-device-detect";

const Stopper = () => {
  const dispatch = useDispatch();
  const dragRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isPlaying, elapsedTime } = useSelector(
    (state: RootState) => state.timer
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isPlaying) {
      interval = setInterval(() => {
        dispatch(incrementTime()); // Increment the elapsed time in the Redux store
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlaying, dispatch]);

  const handlePlay = () => {
    dispatch(startTimer()); // Updates `isPlaying` in the Redux store
    // onPlay(); // Call the prop callback if needed
  };

  const handleStop = () => {
    dispatch(stopTimer()); // Updates `isPlaying` to false
    // onStop(elapsedTime); // Pass the current elapsed time via props
  };

  const handleReset = () => {
    dispatch(resetTimer()); // Resets `elapsedTime` and `isPlaying` in the store
    // onReset(); // Call the reset callback
  };

  const eventHandler = (cb: () => void) => {
    return (event: React.MouseEvent | React.TouchEvent) => {
      if (
        (isMobile && event.type === "touchstart") ||
        (!isMobile && event.type === "click")
      ) {
        cb();
      }
    };
  };

  return (
    <Draggable nodeRef={dragRef}>
      <Box
        ref={dragRef}
        sx={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          bottom: 16,
          touchAction: "none", // Prevent touch gestures from interfering
          left: 16,
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          zIndex: 10000,
          gap: 2,
          cursor: "grab",
          padding: 2,
        }}
      >
        <IconButton
          onClick={eventHandler(() => setIsOpen(!isOpen))}
          onTouchStart={eventHandler(() => setIsOpen(!isOpen))}
          size="sm"
        >
          {isOpen ? <Close /> : <Timelapse />}
        </IconButton>
        {isOpen && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Typography level="h4" textAlign="center">
              {new Date(elapsedTime * 1000).toISOString().slice(14, 19)}
            </Typography>

            <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
              <IconButton
                sx={{
                  backgroundColor: !isPlaying
                    ? themeBase.colorSchemes.new_design.palette.gray_light
                    : themeBase.colorSchemes.new_design.palette.white,
                }}
                color="success"
                onClick={eventHandler(handlePlay)}
                onTouchStart={eventHandler(handlePlay)}
                disabled={isPlaying}
              >
                <PlayArrow />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: isPlaying
                    ? themeBase.colorSchemes.new_design.palette.gray_light
                    : themeBase.colorSchemes.new_design.palette.white,
                }}
                color="danger"
                onClick={eventHandler(handleStop)}
                onTouchStart={eventHandler(handleStop)}
                disabled={!isPlaying}
              >
                <Stop />
              </IconButton>
              <IconButton
                onClick={eventHandler(handleReset)}
                onTouchStart={eventHandler(handleReset)}
              >
                <RestartAlt />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Draggable>
  );
};

export default Stopper;
