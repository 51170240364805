import {
  ArrowBack,
  BackHand,
  Edit,
  List,
  SportsMartialArts,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import useNavigateWithProvider from "../../hooks/useNavigateWithProvider";
import { useLocation, useParams } from "react-router-dom";
import { useReward } from "react-rewards";
import { Box, Typography } from "@mui/joy";
import { Icon } from "@mui/material";
import { themeBase } from "../../theme";
import BasicModal from "../../components/basic-modal";
import ExerciseFilterPicker from "./components/exercise-filter-picker";
import SubmitButton from "../../components/buttons";
import { umbracoApi } from "../../store/api/umbraco-api";

const FinishScreen = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { courseId } = useParams();
  const [fetch] = umbracoApi.useLazyGetCoursesByIdQuery();
  const navigateWithProvider = useNavigateWithProvider();
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  useEffect(() => {
    fetch({ id: parseInt(courseId as string) });
    reward();
  }, [courseId]);

  // const continueStudy = () => {
  //   if (location.pathname.includes("exercise"))
  //     return navigateWithProvider(`/course/${courseId}/exercises`);
  //   return navigateWithProvider(`/course/${courseId}/smart-lessons`);
  // };

  // const navigateToSmartLesson = () => {
  //   if (courseId !== "1144")
  //     return navigateWithProvider(`/course/${courseId}/exercises`);
  //   return navigateWithProvider(`/course/${courseId}/exercise/1189`);
  // };

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 5 }}>
      <Typography level="title-lg">
        כל הכבוד, סיימת את הפרק! הידע שלך הוא ב 15% העליונים של התלמידים בפרק
        זה, נראה שאתה תותח אמיתי בניהול זמן! אנחנו ממליצים לך לעבור לתרגול הבינה
        המלאכותית כדי להמשיך לתרגל, או לחילופין לעבור לשיעור החכם הבא
      </Typography>
      <Icon
        component={SportsMartialArts}
        sx={{ fontSize: 60 }}
        htmlColor={themeBase.colorSchemes.light.palette.primary[500]}
      />
      <Box sx={{ gap: 2, display: "flex", flexDirection: "row" }}>
        <BasicModal
          title="תרגול לפי נושאים"
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
        >
          <ExerciseFilterPicker />
        </BasicModal>
        <SubmitButton
          text={"חזרה לקורס"}
          loadingText={"חזרה לקורס"}
          isLoading={false}
          disabled={false}
          onClick={() => navigateWithProvider("/")}
        />
      </Box>
      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default FinishScreen;
