import { REACT_APP_SERVER_DOMAIN } from "../../../base-env";

export const uploadImages = async (unitId: number, images: File[]) => {
    const formData = new FormData();
    images.forEach((file) => {
        formData.append("files", file);
    });
    try {
        const uploadResponse = await fetch(
            `${REACT_APP_SERVER_DOMAIN}/upload/${unitId}`,
            {
                method: "POST",
                body: formData,
                credentials: "include", // Add this if you need to send cookies
            }
        );

        if (!uploadResponse.ok) {
            throw new Error("error while uploading files");
        }

        return await uploadResponse.json() as string[];
    } catch (error) {
        console.log(error);
        return;
    }
};
