import { Typography, Modal, Box } from "@mui/joy";
import { useState, useEffect } from "react";

interface ContentUnitProps {
  content: string;
}

const RichText = ({ content }: ContentUnitProps) => {
  const [zoomedImg, setZoomedImg] = useState<string | null>(null);
  const [playingVideo, setPlayingVideo] = useState<string | null>(null);

  const handleImageClick = (src: string) => {
    setZoomedImg(src);
  };

  // Add click handlers to <img> and <iframe> elements dynamically
  useEffect(() => {
    const container = document.getElementById("rich-text-container");
    if (container) {
      const images = container.querySelectorAll("img");
      const iframes = container.querySelectorAll("iframe");

      images.forEach((img) => {
        img.style.cursor = "pointer";
        img.style.maxWidth = "100%";  // Make images responsive
        img.style.height = "auto";    // Keep aspect ratio
        img.onclick = () => handleImageClick(img.src);
      });

      iframes.forEach((iframe) => {
        iframe.style.position = "relative";
        iframe.style.maxWidth = "100%";  // Make iframe responsive
        iframe.style.height = "auto";
        iframe.style.display = "block";
        iframe.style.borderRadius = "8px"; // Optional: rounded corners

        // Add a wrapper for iframes to maintain 16:9 aspect ratio
        const iframeWrapper = document.createElement('div');
        iframeWrapper.style.position = "relative";
        iframeWrapper.style.paddingTop = "56.25%";  // 16:9 aspect ratio
        iframeWrapper.style.height = "0";
        iframeWrapper.style.overflow = "hidden";
        iframeWrapper.style.maxWidth = "100%";
        iframeWrapper.style.display = "block";

        // Position the iframe inside the wrapper
        iframe.style.position = "absolute";
        iframe.style.top = "0";
        iframe.style.left = "0";
        iframe.style.width = "100%";
        iframe.style.height = "100%";

        iframe.parentNode?.insertBefore(iframeWrapper, iframe);
        iframeWrapper.appendChild(iframe);
      });
    }
  }, [content]);

  const closeModal = () => {
    setZoomedImg(null);
    setPlayingVideo(null);
  };

  return (
    <>
      <Typography>
        <div
          id="rich-text-container"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Typography>

      {/* Full-Screen Image Modal */}
      <Modal
        open={!!zoomedImg}
        onClose={closeModal}
        aria-labelledby="image-fullscreen-modal"
        aria-describedby="fullscreen-image"
      >
        <Box
          onClick={closeModal} // Close modal when clicking outside or on the image
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            cursor: "pointer",
          }}
        >
          {zoomedImg && (
            <img
              src={zoomedImg}
              alt="Full-Sized"
              onClick={closeModal} // Close modal when clicking the image
              style={{
                display: "block",
                maxWidth: "90vw",  // Make the image responsive inside modal
                height: "auto",
                objectFit: "contain",  // Preserve aspect ratio inside modal
                cursor: "pointer", // Indicate image is clickable
              }}
            />
          )}
        </Box>
      </Modal>

      {/* Video Modal */}
      <Modal
        open={!!playingVideo}
        onClose={closeModal}
        aria-labelledby="video-fullscreen-modal"
        aria-describedby="fullscreen-video"
      >
        <Box
          onClick={closeModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            cursor: "pointer",
          }}
        >
          {playingVideo && (
            <video
              src={playingVideo}
              controls
              autoPlay
              style={{
                display: "block",
                maxWidth: "90vw",
                height: "auto",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default RichText;
