// timerSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface TimerState {
  isPlaying: boolean;
  elapsedTime: number;
}

const initialState: TimerState = {
  isPlaying: false,
  elapsedTime: 0,
};

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    startTimer: (state) => {
      state.isPlaying = true;
    },
    stopTimer: (state) => {
      state.isPlaying = false;
    },
    resetTimer: (state) => {
      state.elapsedTime = 0;
      state.isPlaying = false;
    },
    incrementTime: (state) => {
      state.elapsedTime += 1;
    },
  },
});

export const { startTimer, stopTimer, resetTimer, incrementTime } = timerSlice.actions;
export default timerSlice.reducer;
